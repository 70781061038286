import settings from '../../config/settings';
import { getRecords } from "../helper";

const getItems = async (enablePOS, storeId, placeItems) => {
  let items = [];
  if(enablePOS === true){
    const response = await fetch(
      `${settings.myfireServer}/${storeId}/products`
    );
    if (response.status === 200) {
      const data = await response.json();
      items = data.rows.map(({
        id,
        name,
        description,
        category_id,
        sale_price,
        url
      }) => ({
        id,
        itemName: name,
        description,
        category: category_id,
        ourSpecial: false,
        price: sale_price,
        imageSrc: url,
        dealPrice: null,
        dealValidFrom: null,
        dealValidTo: null,
        available: true
      }));
    }
  } else {
    items = await getRecords("items", Object.keys(placeItems));
  }
  return items;
};

export { getItems };
