import React, {useContext} from 'react';
import { logEvent } from "firebase/analytics";
import { analytics } from '../../../lib/firebase';
import { setShowPreview } from '../../Store/actions';
import { StateContext } from '../../../data/state/state-context';

const ActivationMessage = ({setCurrentPage}) => {
  const [, dispatch] = useContext(StateContext);
  const onClickActivateNow = () => {
    logEvent(analytics, 'activatenow_clicked');
    setCurrentPage('choose-phone-number');
  };

  const onClickContactUs = () => {
    logEvent(analytics, 'contactus_clicked');
    setCurrentPage('contact-us');
  };

  const showPreview = () => {
    logEvent(analytics, 'showpreview_clicked');
    dispatch(setShowPreview());
  }

  /* <!--
    Modal panel, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      To: "opacity-100 translate-y-0 sm:scale-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100 translate-y-0 sm:scale-100"
      To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  --> */
  return (
    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-500 sm:mx-0 sm:h-10 sm:w-10">
            {/* <!-- Heroicon name: outline/rocket-launch --> */}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" className="w-6 h-6">
              <path strokeLinecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
            </svg>
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">This digital menu is ready for activation</h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">This menu was built by <a href="https://www.ferrypal.com" target="_blank" rel="noreferrer">FerryPal</a>&#174;.
              <a href="https://www.ferrypal.com" target="_blank" rel="noreferrer">FerryPal</a> helps local businesses to jumpstart their business online for free! It takes no commissions on orders.</p>
            </div>
            <div className="mt-2">
              <p className="text-sm text-gray-500">Have a question? <span className="font-medium text-green-600" onClick={onClickContactUs}>Tap here to Contact Us</span></p>
            </div>
            <div className="mt-2">
              <p className="text-sm text-amber-700">If the menu is not activated by March 31, 2023 midnight, it will be automatically deleted.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button onClick={onClickActivateNow} type="button" className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">Activate Now</button>
        <button onClick={showPreview} type="button" className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Preview</button>
      </div>
    </div>
  );
};

export default ActivationMessage;
