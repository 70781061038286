import React from "react";
import PlacesAutocomplete from "./PlaceAutocomplete";

const Delivery = ({ deliveryOptions, userDeliveryLocations, setAddress, selectedAddress, onDateChange, onChangeAddress,locationInfo }) => {
  return (
    <div className="p-4 flex items-center justify-left bg-white">
      <form class="w-full max-w-screen-lg">
        <fieldset class="space-y-6">
          <div class="grid sm:grid-cols-5 gap-6">
            {userDeliveryLocations  && userDeliveryLocations.map((deliveryLocation, i)=>{
              let checked = selectedAddress.address === deliveryLocation.address;
              return(
                <label for={`address-${i+1}`} class="relative flex flex-col bg-white p-5 rounded-lg shadow-md cursor-pointer">
                  <span class="font-semibold text-gray-900 leading-tight uppercase mb-3"></span>
                  <span>
                    <span class="text-sm font-normal text-gray-900 z-50">{deliveryLocation.address}</span>
                  </span>
                  <input type="radio" name="address" id={`address-${i+1}`} value={deliveryLocation.address} class="absolute h-0 w-0 appearance-none"  onClick={()=>onChangeAddress(deliveryLocation)} checked={checked} />
                  <span aria-hidden="true" class="hidden absolute inset-0 border-2 border-themeone-dark bg-opacity-10 rounded-lg">
                    <span class="absolute top-4 right-4 h-6 w-6 inline-flex items-center justify-center rounded-full bg-themeone-light">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 text-themeone-dark">
                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                      </svg>
                    </span>
                  </span>
                </label>
                )
              })
            }
          </div>
        </fieldset>
        <PlacesAutocomplete
          setAddress={(val)=> {
            setAddress(val);
            // setIsAddressValid(val.trim().length > 0)
          }}
          placeholder="Address"
          setLatLng={() => {}}
          defaultValue=''
          isValid={true}
          onFocusInput={()=>{}}
          locationInfo={locationInfo}
          deliveryOptions={deliveryOptions}
        />
        <label for="deliverydate" className="text-lg my-2  mb-4 line-clamp-3  text-gray-900">Delivery date</label>
        <input
          id="deliverydate"
          name="deliverydate"
          className="shadow appearance-none border rounded w-half py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
          type={"datetime-local"}
          onChange={onDateChange}
        />
        <p class="mt-2 text-grey-200 text-xs">
          Leave it blank if you want the delivery as soon as possible
        </p>
      </form>
    </div>
    )
  }

  export default Delivery;
