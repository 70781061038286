import * as ActionTypes from '../../data/state/action-types';

const initialState = {
  categories: [ /* {id, name, order, discountPercentage, dealValidFrom, dealValidTo} */ ],
  items: [
    /* { itemId, title, description, category, ourSpecial, price, image, frequency, dealPrice, dealValidFrom, dealValidTo */
  ],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case ActionTypes.LOAD_ITEMS:
      return {
        ...state,
        items: action.items,
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
