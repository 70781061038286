import React, { useEffect, useContext, useState } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber, setPersistence, browserLocalPersistence } from "firebase/auth";
import { isPossiblePhoneNumber } from 'react-phone-number-input'

import { StateContext } from '../../../data/state/state-context';
import { analytics, auth, db } from "../../../lib/firebase";
import { logEvent } from 'firebase/analytics';

const ChoosePhoneNumber = ({setCurrentPage, phoneNumber, setPhoneNumber}) => {
  const [rootState] = useContext(StateContext);
  const { availableNumbers } = rootState.store;
  const [invalidPhone, setInvalidPhone] = useState(false);

  const buttonDisabled = "mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-gray-300 px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm";
  const buttonEnabled = "inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm";

  const onSubmitPhone = ()=> {
    logEvent(analytics, 'submitchoosephone_clicked', {phone_number: phoneNumber});
    if(!isPossiblePhoneNumber(phoneNumber)){
      console.error(phoneNumber, 'appears to be invalid?');
      setInvalidPhone(true);
      return;
    }

    const appVerifier = window.recaptchaVerifier;
    setPersistence(auth, browserLocalPersistence).then(() => {
      signInWithPhoneNumber(auth, phoneNumber, appVerifier).then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setCurrentPage('verification-code');
      }).catch((error) => {
        // Error; SMS not sent
        console.log('signInWithPhoneNumber error', error.toString());

        // // recaptcha issue? reset it
        // window.recaptchaVerifier.render().then(function(widgetId) {
        //   grecaptcha.reset(widgetId);
        // });
      });
    }).catch((error) => {
      console.log('setPersistence error', error.toString());
    });;
  };

  const onClickBack = ()=>{
    logEvent(analytics, 'backfromchoosephone_clicked');
    setCurrentPage('activation-message');
  };

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier('submit-phone', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log('recaptch response', response);
      }
    }, auth);
  }, []);

  return (
    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-500 sm:mx-0 sm:h-10 sm:w-10">
            {/* <!-- Heroicon name: outline/phone --> */}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" className="w-6 h-6">
              <path strokeLinecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
            </svg>
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">Choose a phone number</h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">Awesome! Before we proceed, we need to verify you are authorized to activate this menu.</p>
            </div>
            <div className="mt-2">
              <p className="text-sm text-gray-500">Below are the phone number(s) associated with this store as per our records. Choose a number, and we'll send a verification code.</p>
            </div>
            <div className="mt-2">
              {
                availableNumbers.map((phone) => (
                  <div className="form-check mt-2">
                    <input type="radio" name="phone-number" id={phone} onChange={()=>{setInvalidPhone(false); setPhoneNumber(phone)}} className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer" />
                    <label className="form-check-label inline-block text-gray-800" for={phone}>
                      {phone}
                    </label>
                  </div>
                ))
              }
            </div>
            { invalidPhone === true &&
              <div className="mt-4">
                <p className="text-sm text-amber-700">That number is not valid</p>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button id="submit-phone" disabled={!phoneNumber} onClick={onSubmitPhone} type="button" className={!phoneNumber ? buttonDisabled : buttonEnabled}>Send Code</button>
        <button onClick={onClickBack} type="button" className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Back</button>
      </div>
    </div>
  );
};

export default ChoosePhoneNumber;
