import { collection, query, where, orderBy, limit, getDocs } from "firebase/firestore";

import * as ActionTypes from '../../data/state/action-types';
import { db } from '../../lib/firebase';
import { getRecords } from "../../lib/helper";

const loadOrderAction = (order) => (
  {
    type: ActionTypes.LOAD_ORDER,
    order,
  }
);

// getOrderStatus is a hackery until a better "status" is available in "trips"
const getOrderStatus = (itemCount, itemsReadyCount, acceptedByPlaceAt, rejectedByPlaceAt, pickedUpAt, isOrderDelivery) => {
  if(rejectedByPlaceAt) {
    return 'not-accepted';
  }
  if(!acceptedByPlaceAt) {
    return 'pending';
  }
  if(pickedUpAt !== null) {
    return isOrderDelivery === true ? 'out-for-delivery' : 'delivered';
  }
  if(itemsReadyCount === itemCount) {
    return 'ready';
  }
  if(itemsReadyCount > 0) {
    return 'in-progress';
  }

  return 'received';
};

const loadOrders = async (dispatch, userId, storeId) => {
  const q = query(
    collection(db, "trips"),
    where("userId", "==", userId),
    where("placeId", "==", storeId),
    orderBy("createdAt", "desc"),
    limit(10)
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (doc) => {
    // console.log(doc.id, " => ", doc.data());
    const { when, orderItems, acceptedByPlaceAt, rejectedByPlaceAt, pickedUpAt, isOrderDelivery } = doc.data();
    if(Array.isArray(orderItems?.self) !== true || orderItems.self.length <= 0) {
      return; // querySnapshot.forEach callback
    }

    const orderInfo = {
      _id: doc.id,
      id: doc.id,
      timestamp: when.toDate(),
      amount_total: 0,
      items: [],
    };
    const oiRecords = await getRecords('order_item', orderItems.self);
    let itemsReadyCount = 0;
    for (const oi of oiRecords) {
      const items = await getRecords('items', [oi.itemId]);
      if(Array.isArray(items) !== true || items.length <= 0) {
        continue;
      }
      const {id, itemName, imageSrc } = items[0];
      const { orderReady, price, quantity, notes } = oi;

      if(orderReady === true){
        itemsReadyCount++;
      }
      orderInfo.amount_total += (price * quantity);
      orderInfo.items.push({
        _id: id,
        title: itemName,
        price,
        image: imageSrc,
        qty: quantity,
        notes,
      });
    }

    orderInfo.order_status = {
      current: {
        status: getOrderStatus(oiRecords.length, itemsReadyCount, acceptedByPlaceAt, rejectedByPlaceAt, pickedUpAt, isOrderDelivery)
      }
    };

    dispatch(loadOrderAction(orderInfo));
  });
};

export { loadOrders };
