import React from "react";
import { Link } from "react-router-dom";

function Footer({ storeId }) {
  const gmailHandler = () => {
    window.open(
      "mailto:" +
      "hello@ferrypal.com" +
      "?subject=" +
      "[" + storeId + "] Hello" +
      "&body=" +
      " (Please keep this identifier in the subject and content of the mail) Identifier: " + storeId,
      "_self"
    );
  };
  return (
    <div className="bg-themeone-footer py-8 px-6 text-gray-200  lg:text-base text-sm font-family">
      <div className="w-full mx-auto">
        <div className="flex w-f">
          <div className="flex w-1/3">
            <a href="https://www.ferrypal.com" target="_blank" rel="noreferrer">
              <span className="cursor-pointer hover:text-white text-xs">Empowered by </span>
              <span className="cursor-pointer hover:text-white text-lg">FerryPal&#174;</span>
            </a>
          </div>
          <div className="flex w-1/3 justify-center">
            <div className="md:w-6 w-5 mx-2 my-auto">
              <img
                width={25}
                height={25}
                src="/img/social/email.svg"
                objectFit="contain"
                className="cursor-pointer"
                alt="email"
                onClick={gmailHandler}
              />
            </div>
            <div className="md:w-6 w-5 mx-2 my-auto">
              <img
                width={25}
                height={25}
                src="/img/social/twitter.png"
                objectFit="contain"
                className="cursor-pointer"
                alt="twitter"
                onClick={() => {
                  window.open('https://twitter.com/ferrypal');
                }}
              />
            </div>
          </div>
          <div className="flex w-1/3">&nbsp;</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
