import React, { useContext, useState } from 'react';
import OtpInput from 'react-otp-input';
import { doc, setDoc, arrayUnion } from "firebase/firestore";

import { analytics, db } from "../../../lib/firebase";
import { StateContext } from '../../../data/state/state-context';
import { logEvent } from 'firebase/analytics';

const VerificationCode = ({setCurrentPage, phoneNumber}) => {
  const [rootState] = useContext(StateContext);
  const [otp, setOtp] = useState('');
  const [otpProblem, setOtpProblem] = useState(false);
  const { storeId } = rootState.store;

  const createUser = (userId) => {
    setDoc(doc(db, "users", userId), {
      displayName: 'Store User',
      phoneNumber,
      userType: 'store-user',
      createdAt: new Date(),
      places: {[storeId]: 1}
    }, { merge: true });

    setDoc(doc(db, "places", storeId), {
      storeAdmins: arrayUnion(userId),
      unclaimed: false,
    }, { merge: true });
  };

  const verifyCode = () => {
    logEvent(analytics, 'submitverificationcode_clicked', {otp});
    window.confirmationResult.confirm(otp).then((result) => {
      // User signed in successfully.
      const user = result.user;
      createUser(user.uid);
      setCurrentPage('celebrate');
    }).catch((error) => {
      // User couldn't sign in (bad verification code?)
      console.log('confirmationResult error', error.toString());
      setOtpProblem(true);
    });
  };

  const onClickStartOver = () => {
    logEvent(analytics, 'backfromverificationcode_clicked');
    setCurrentPage('activation-message');
  };

  const buttonDisabled = "mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-gray-300 px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm";
  const buttonEnabled = "inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm";

  return (
    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-500 sm:mx-0 sm:h-10 sm:w-10">
            {/* <!-- Heroicon name: outline/phone --> */}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" className="w-6 h-6">
              <path strokeLinecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
            </svg>
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">Verification code</h3>
            <div className="mt-4">
              <p className="text-sm text-gray-500">A verification code has been sent, please enter it.</p>
            </div>
            <div className="mt-4">
              <OtpInput
                value={otp}
                onChange={(value) => { setOtpProblem(false); setOtp(value); }}
                numInputs={6}
                isInputNum={true}
                shouldAutoFocus={true}
                containerStyle={{justifyContent: 'space-evenly', marginTop: 10, marginBottom: 20}}
                inputStyle={{
                  height: 40,
                  width: 32,
                  border: 'solid',
                  borderWidth: 1,
                  borderRadius: 8,
                }}
              />
              { otpProblem === true && <p className="text-red-500 text-xs italic">There was a problem</p>}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button disabled={otp.length !== 6} onClick={verifyCode} type="button" className={otp.length !== 6 ? buttonDisabled : buttonEnabled}>Submit</button>
        <button onClick={onClickStartOver} type="button" className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Start Over</button>
      </div>
    </div>
  );
};

export default VerificationCode;
