import React from "react";

const PickupOrDelivery = ( {onChange, hasDelivery} ) => {
return (
    <div className="p-4 flex items-center justify-left">
    <div class="w-full max-w-screen-md">
      <fieldset class="space-y-6">
        <div class="grid sm:grid-cols-2 gap-6">
          <label for="pickup" class="relative flex flex-col bg-white p-5 rounded-lg shadow-md cursor-pointer">
            <span class="font-semibold text-gray-900 leading-tight uppercase mb-3">Pickup</span>
            <span>
              <span class="text-sm font-semibold text-gray-500">Customer pickup order from store</span>
            </span>
            <input type="radio" name="plan" id="pickup" value="pickup" onClick={onChange} class="absolute h-0 w-0 appearance-none" defaultChecked />
            <span aria-hidden="true" class="hidden absolute inset-0 border-2 border-themeone-dark bg-opacity-10 rounded-lg">
              <span class="absolute top-4 right-4 h-6 w-6 inline-flex items-center justify-center rounded-full bg-themeone-light">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 text-themeone-dark">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
              </span>
            </span>
          </label>
          {
            hasDelivery &&
            <label for="delivery" class={`relative flex flex-col bg-white p-5 rounded-lg shadow-md cursor-pointer`}>
              <span class="font-semibold text-gray-900 leading-tight uppercase mb-3">Delivery</span>
              <span>
                <span class="text-sm font-semibold text-gray-500">We deliver to your doorstep</span>
              </span>
              <input type="radio" name="plan" id="delivery" value="delivery" onClick={onChange} class="absolute h-0 w-0 appearance-none" />
              <span aria-hidden="true" class="hidden absolute inset-0 border-2 border-themeone-dark bg-opacity-10 rounded-lg">
                <span class="absolute top-4 right-4 h-6 w-6 inline-flex items-center justify-center rounded-full bg-themeone-light">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 text-themeone-dark">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                </span>
              </span>
            </label>
          }
        </div>
      </fieldset>
    </div>
    </div>
);

};

export default PickupOrDelivery;
