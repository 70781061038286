import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './i18n';
import Layout from './components/Layout/Layout';
import Router from './screens/Router';
import { useRootReducer, StateContext } from './data/state/state-context';
import GenericRouter from './screens/Router/components/GenericRouter';

function App() {
  const [rootState, dispatch] = useRootReducer();
  return (
    <StateContext.Provider value={[rootState, dispatch]}>
      <BrowserRouter basename="/stores">
        <Layout>
          <Router />
          <ToastContainer autoClose={2000} />
        </Layout>
      </BrowserRouter>
      <BrowserRouter basename="/">
          <GenericRouter />
      </BrowserRouter>
    </StateContext.Provider>
  );
}

export default App;
