import React, { useState, useContext, useEffect, useRef } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber, setPersistence, browserLocalPersistence } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import OtpInput from 'react-otp-input';
import { StateContext } from '../../../data/state/state-context';
import { auth, db } from "../../../lib/firebase";
import { getCountryCode } from "../../../utils";
import { emptyCartAction, placeOrder } from "../../Cart/actions";

const countryCode = getCountryCode();

const PhoneSection = ({setOtpSent, displayName, setDisplayName, phoneNumber, setPhoneNumber}) => {
  const [rootState] = useContext(StateContext);
  const { smartCardScan } = rootState.main;
  const phoneInputRef = useRef();
  const [invalidName, setInvalidName] = useState(false);

  const onSubmitPhone = ()=> {
    if(displayName.trim() === ''){
      setInvalidName(true);
      return;
    }

    if(!isPossiblePhoneNumber(phoneNumber)){
      return;
    }

    const appVerifier = window.recaptchaVerifier;
    setPersistence(auth, browserLocalPersistence).then(() => {
      signInWithPhoneNumber(auth, phoneNumber, appVerifier).then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setOtpSent(true);
      }).catch((error) => {
        // Error; SMS not sent
        console.log('signInWithPhoneNumber error', error.toString());

        // // recaptcha issue? reset it
        // window.recaptchaVerifier.render().then(function(widgetId) {
        //   grecaptcha.reset(widgetId);
        // });
      });
    }).catch((error) => {
      console.log('setPersistence error', error.toString());
    });;
  };

  useEffect(() => {
    if (phoneInputRef.current) {
      phoneInputRef.current.style.outlineWidth = 0; // this is on the input filed itself
    }

    window.recaptchaVerifier = new RecaptchaVerifier('submit-phone', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log('recaptch response', response);
      }
    }, auth);
  }, []);

  return (
    <>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
          Your name
        </label>
        <input
          value={displayName}
          onChange={(event) => { setInvalidName(false); setDisplayName(event.target.value); }}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="username"
          type="text"
          placeholder="Full name"
        />
        {invalidName === true && <p className="text-red-500 text-xs italic">Please enter your name</p>}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
          Phone number
        </label>
        <PhoneInput
          ref={phoneInputRef}
          style={{
            border: 'solid',
            borderRadius: '4px',
            borderWidth: '1px',
            borderColor: '#d5d3d3',
            height: '2.5em',
            borderStyle: 'solid',
            paddingLeft: '4px',
          }}
          defaultCountry={countryCode}
          international={true}
          placeholder="Phone number"
          value={phoneNumber}
          onChange={setPhoneNumber}/>
      </div>
      <div style={{paddingBottom: 20}}>
          <span>{smartCardScan === true ? 'Please provide above info to help us serve you better.' : 'The store may need to contact you to verify the order.'}</span><br/>
          Please enter a valid phone number. A verification code<br/>
          will be sent to the number. Carrier charges may apply.
      </div>
      <div className="flex justify-end">
        <button id="submit-phone" onClick={onSubmitPhone} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
          Send code
        </button>
      </div>
    </>
  );
};

const OtpSection = ({ setOtpSent, createUser }) => {
  const [otp, setOtp] = useState('');
  const [otpProblem, setOtpProblem] = useState(false);
  const onBack = () => setOtpSent(false);
  const verifyCode = () => {
    window.confirmationResult.confirm(otp).then((result) => {
      // User signed in successfully.
      const user = result.user;
      createUser(user.uid);
    }).catch((error) => {
      // User couldn't sign in (bad verification code?)
      console.log('confirmationResult error', error.toString());
      setOtpProblem(true);
    });
  };

  return (
    <>
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">Verification code</div>
        <p className="text-gray-700 text-base">
          A verification code has been sent, please enter it
        </p>
        <OtpInput
          value={otp}
          onChange={(value) => { setOtpProblem(false); setOtp(value); }}
          numInputs={6}
          isInputNum={true}
          shouldAutoFocus={true}
          containerStyle={{justifyContent: 'space-evenly', marginTop: 10, marginBottom: 20}}
          inputStyle={{
            height: 36,
            width: 28,
            border: 'solid',
            borderWidth: 1,
            borderRadius: 8,
          }}
        />
        { otpProblem === true && <p className="text-red-500 text-xs italic">There was a problem</p>}
      </div>
      <div className="flex items-center justify-between">
        <button onClick={onBack} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
          Back
        </button>
        <button onClick={verifyCode} disabled={otp.length !== 6} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
          Submit
        </button>
      </div>
    </>
  );
}

const SignIn = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const navigate = useNavigate();
  const storeId = rootState.store.storeId || sessionStorage.getItem('storeId');
  const { items: cartItems, details } = rootState.cart;
  const [displayName, setDisplayName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpSent, setOtpSent] = useState(false);

  const clearCart = () => { dispatch(emptyCartAction()) };

  const createUser = async (userId) => {
    setDoc(doc(db, "users", userId), {
      displayName,
      phoneNumber,
      userType: 'app-user',
      createdAt: new Date(),
      places: {[storeId]: 1}
    }, { merge: true });

    if(cartItems?.length > 0) {
      const {
        notes,
        pickdel,
        pickupValue,
        deliveryValue,
        userDeliveryLocations,
      } = details;

      await placeOrder(userId, storeId, notes, cartItems, pickdel, pickupValue, deliveryValue, userDeliveryLocations);
      clearCart();
      alert('Order placed');
    }
    navigate(`/${storeId}`);
  };

  return (
    <>
      <div className="bg-gray-100 py-10 md:px-6 heightFix">
        <main className="max-w-screen-xl mx-auto">
          <div className="flex items-center justify-center w-full  px-6 lg:py-20 sm:py-10 py-4">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              {
                otpSent !== true ? (
                  <PhoneSection
                    setOtpSent={setOtpSent}
                    displayName={displayName}
                    setDisplayName={setDisplayName}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                  />
                ) : (
                  <OtpSection setOtpSent={setOtpSent} createUser={createUser} />
                )
              }
            </form>
          </div>
        </main>
      </div>
    </>
  );
};

export default SignIn;
