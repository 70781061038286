import React, { useState } from 'react';
import ActivationMessage from './ActivationMessage';
import Celebrate from './Celebrate';
import ChoosePhoneNumber from './ChoosePhoneNumber';
import ContactUs from './ContactUs';
import VerificationCode from './VerificationCode';

const Router = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [ currentPage, setCurrentPage ] = useState('activation-message');
  switch (currentPage) {
    case 'activation-message':
      return <ActivationMessage setCurrentPage={setCurrentPage} />
    case 'choose-phone-number':
      return <ChoosePhoneNumber setCurrentPage={setCurrentPage} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
    case 'verification-code':
      return <VerificationCode setCurrentPage={setCurrentPage} phoneNumber={phoneNumber} />
    case 'celebrate':
      return <Celebrate phoneNumber={phoneNumber} />
    case 'contact-us':
      return <ContactUs setCurrentPage={setCurrentPage} />
    default:
      return null;
  }
}

const Unclaimed = () => {
  return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      {/* <!--
        Background backdrop, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      --> */}
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Router />
        </div>
      </div>
    </div>
  );
};

export default Unclaimed;
