import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";

// Local modules
import { StateContext } from "../../data/state/state-context";
import { ratingLabels } from "./Rateus";

function Testimonials() {
  const [rootState] = useContext(StateContext);
  const { ratings } = rootState.store;

  const [testimonials, setTestimonials] = useState([]);
  useEffect(() => {
    const _testimonials = ratings.filter((tst) => tst.disabled !== true);
    setTestimonials(_testimonials);
  }, [ratings]);

  return testimonials.length > 0 ? (
    <div className="px-6 py-20 font-family">
      <div className="mx-auto max-w-screen-xl">
        <h2 className="heading after:bg-themeone-dark text-themeone-head">
          What our customers say
        </h2>
        <div className="flex justify-between mt-20 italic lg:text-base  text-sm gap-6 sm:flex-row flex-col">
          {testimonials.map((testMn) => (
            <div className="sm:max-w-xs">
              <div className="font-extrabold text-6xl -mb-8">"</div>
              <Typography variant="h4" gutterBottom>
                {ratingLabels[testMn.value]}
              </Typography>
              <Rating name="read-only-rating" value={testMn.value} readOnly />
              <p>{testMn.comments}</p>
              <div className="flex items-center sm:mt-8 mt-4 gap-2">
                <div>
                  <img
                    src="/img/profile_pic.svg"
                    alt=""
                    width={24}
                    height={24}
                    objectFit="contain"
                    className="rounded-full"
                  />
                </div>

                <span>{testMn.displayName}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null;
}

export default Testimonials;
