// Action Types

export const SET_USER = 'SET_USER';
export const SET_STORE = 'SET_STORE';
export const STORE_CLAIMED = 'STORE_CLAIMED';
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const LOAD_ITEMS = 'LOAD_ITEMS';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const INCREASE_QTY = 'INCREASE_QTY';
export const DECREASE_QTY = 'DECREASE_QTY';
export const EMPTY_CART = 'EMPTY_CART';
export const CART_DETAILS = 'CART_DETAILS';
export const LOAD_ORDER = 'LOAD_ORDER';
export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';
export const LOAD_SEARCH_RESULT = 'LOAD_SEARCH_RESULT';
export const UPDATE_STORE_RATINGS = 'UPDATE_STORE_RATINGS';
export const TOGGLE_STORE_RATING = 'TOGGLE_STORE_RATING';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const QUERY_PARAMS = 'QUERY_PARAMS';
