import React from 'react';
import { Routes, Route } from "react-router-dom";
import Cart from '../../Cart';
import Orders from '../../Orders';
import Items from '../../Items';
import SignIn from '../../SignIn';
import OrderDetails from '../../../components/Order/OrderDetails';

const Router = () => {
  return (
    <Routes>
      <Route path="/:storeId" element={<Items />} />
      <Route path="/:storeId/cart" element={<Cart />} />
      <Route path="/:storeId/orders" element={<Orders />} />
      <Route path="/:storeId/order-details/:tripId" element={<OrderDetails />} />
      <Route path="/:storeId/signin" element={<SignIn />} />
    </Routes>
  );
};

export default Router;
