import React from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';

const PlacesAutocomplete = ({setAddress, placeholder, setLatLng, defaultValue = '', isValid, onFocusInput, locationInfo, deliveryOptions}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
    defaultValue
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  /* handleSelect gets called with following details
   {
      "description": "The Dubai Mall Bus Station - D71 - Dubai - United Arab Emirates",
      "place_id": "ChIJ20rnoe5DXz4R-qV8jc-ax4I",
      "structured_formatting": {
        "main_text": "The Dubai Mall Bus Station",
        "secondary_text": "D71 - Dubai - United Arab Emirates"
      },
      "types": [
        "point_of_interest",
        "establishment"
      ]
    }
   */
  const handleSelect =  ({ description }) => async() => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    const valParam = {
      address: description,
      lastDeliveryDate: null,
      locationInfo: {geocodedAddress: description,lat: "",lng: ""}
    };
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(async({ lat, lng }) => {
        setLatLng({lat, lng});
        setAddress({
          ...valParam,
          locationInfo:{...valParam.locationInfo, lat, lng},
        });

        setValue("");
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <a href="#" class="block py-2 px-4 text-sm text-black hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </a>
        </li>
      );
    });
  return (
    <div className="my-6">
      <div className="flex flex-col">
        <label for="create-store-address" className="text-lg my-2  mb-4 line-clamp-3  text-gray-900">New delivery address</label>
          <input
            type={"search"}
            value={value}
            onChange={handleInput}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            style={isValid === true? {}:{borderColor: 'red'}}
            disabled={!ready}
            placeholder={placeholder}
            id="create-store-address"
            autoComplete="off"
            onFocus={onFocusInput}
          />
        {/* We can use the "status" to decide whether we should display the dropdown or not */}
        {status === "OK" && <ul  className="py-1">{renderSuggestions()}</ul>}
      </div>
    </div>
  );
};

export default PlacesAutocomplete;
