import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Order from "../../../components/Order/Order";
import { StateContext } from '../../../data/state/state-context';
import { loadOrders } from "../actions";

const Orders = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const navigate = useNavigate();
  const storeId = rootState.store.storeId || sessionStorage.getItem('storeId');
  const userId = rootState.main.userId || sessionStorage.getItem('userId');
  const { orders } = rootState.orders;

  useEffect(() => {
    loadOrders(dispatch, userId, storeId);
  }, [userId]);

  return (
    <div className=" heightFix px-6 font-family">
      <main className="max-w-screen-xl mx-auto md:py-20 py-12 pb-20">
        <h1 className="sm:text-2xl text-xl font-semibold border-b-2 mb-2 pb-4 border-gray-200 text-gray-700 h-full">
          Your Orders
        </h1>
        {userId ? (
          <>
            <h2 className="font-medium text-lg  my-2 text-themeone-dark">
              {orders ? (
                <>
                  <span className="font-semibold text-xl mr-2">
                    {orders?.length}
                  </span>
                  Orders
                </>
              ) : (
                <Skeleton width={100} />
              )}
            </h2>
            {orders ? (
              orders.length ? (
                <div className="mt-5 space-y-6">
                  {orders.map(
                    ({
                      _id,
                      id,
                      amount_total,
                      items,
                      timestamp,
                      order_status,
                    }) => (
                      <Order
                        key={`order-${_id}`}
                        id={id}
                        _id={_id}
                        amount_total={amount_total}
                        timestamp={timestamp}
                        items={items}
                        status={order_status?.current?.status}
                      />
                    )
                  )}
                </div>
              ) : (
                <div className="h-full flex items-center justify-center mt-16 sm:w-auto w-3/4 mx-auto sm:max-w-xs ">
                  <img
                    src="/img/empty.svg"
                    width={300}
                    height={300}
                    alt=""
                    objectFit="contain"
                  />
                </div>
              )
            ) : (
              <Skeleton count={12} />
            )}
          </>
        ) : (
          <>
            <div className="text-center sm:text-lg text-base  font-medium mt-12">
              <h2>
                Please
                <span
                  className="link underline text-themeone-light mx-2"
                  onClick={() => navigate(`/${storeId}/signin`)}
                >
                  login
                </span>
                in to view your orders.
              </h2>
              <div className="md:max-w-none max-w-xs sm:w-auto w-3/4 mx-auto">
                <img
                  src="/img/authentication.svg"
                  width={450}
                  height={450}
                  alt=""
                />
              </div>
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default Orders;
