import React from "react";

const ItemCard = ({ item }) => (
  <div key={item.itemId} className="w-full lg:w-1/2 xl:w-1/2 px-8 py-8">
    <a href={`/stores/${item.placeId}`}>
    <div className="max-w-sm w-full lg:max-w-full lg:flex">
      <div className="h-48 lg:h-auto lg:w-48 flex-none bg-cover border border-gray-400 lg:border-r-0 lg:border-gray-400 rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" style={{backgroundImage: `url(${item.image})`}} title="Woman holding a mug">
      </div>
      <div className="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
        <div className="mb-8">
          <p className="text-sm text-gray-600 flex items-center">
            <svg className="fill-current text-gray-500 w-3 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M0 11.386l1.17-1.206c1.951.522 5.313 1.731 8.33 3.597 3.175-4.177 9.582-9.398 13.456-11.777l1.044 1.073-14 18.927-10-10.614z"/>
            </svg>
            Available
          </p>
          <div className="text-gray-900 font-bold text-xl mb-2">{item.title}</div>
          <p className="text-gray-700 text-base">{item.description || 'No description'}</p>
        </div>
        <div className="flex items-center">
          <img className="w-10 h-10 rounded-full mr-4" src={item.storeImage} alt="store" />
          <div className="text-sm">
            <p className="text-gray-900 font-bold leading-none">{item.placeName}</p>
            <p className="text-gray-600 text-xs">+{item.phoneNumber}</p>
            <p className="text-gray-600 text-xs">{item.address}</p>
          </div>
        </div>
      </div>
    </div>
  </a>
  </div>
);

export default ItemCard;
