import * as ActionTypes from '../../data/state/action-types';

const initialState = {
  items: [
    /* { itemId, title, price, description, category, image, qty } */
  ],
  details: {
    notes: '',
    pickdel: 'pickup',
    selectedAddress: {},
    pickupValue: {requestedTime:"ASAP"},
    deliveryValue: {requestedTime:"ASAP",deliveryCharge:0,address:"",locationInfo:{geocodedAddress:"",lat:"",lng:""}},
    userDeliveryLocations: [],
  }
};

const appendItem = (items, item) => {
  let found = false;
  const updatedList = items.map((i) => {
    if(i.itemId !== item.itemId){
      return i;
    }

    found = true;
    return {...i, qty: i.qty + item.qty};
  });

  if(found !== true){
    updatedList.push(item);
  }

  return updatedList;
};

const removeItem = (items, itemId) => items.filter((i) => i.itemId !== itemId);

const changeQty = (items, itemId, change) => {
  return items.map((i) => {
    if(i.itemId !== itemId){
      return i;
    }

    return {...i, qty: i.qty + change};
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_TO_CART:
      return {
        ...state,
        items: appendItem(state.items, {
          itemId: action.itemId,
          title: action.title,
          price: action.price,
          description: action.description,
          category: action.category,
          image: action.image,
          qty: action.qty,
        }),
      };
    case ActionTypes.REMOVE_FROM_CART:
      return {
        ...state,
        items: removeItem(state.items, action.itemId),
      };
    case ActionTypes.INCREASE_QTY:
      return {
        ...state,
        items: changeQty(state.items, action.itemId, 1),
      };
    case ActionTypes.DECREASE_QTY:
      return {
        ...state,
        items: changeQty(state.items, action.itemId, -1),
      };
    case ActionTypes.EMPTY_CART:
      return {
        ...state,
        items: [],
      };
    case ActionTypes.CART_DETAILS:
      return {
        ...state,
        details: {...state.details, ...action.details},
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
