import React from "react";
import Fade from "react-reveal/Fade";

function Banner({ placeName, items, imageSrc, verified }) {
  const ourMenu = () => {
    window.scrollTo({top:document.getElementById("menu").offsetTop - 90, behavior: 'smooth'});
    //window.location.href='#products-feed'
  };

  const viewMore =()=>{
    window.scrollTo({top:document.getElementById("about").offsetTop - 90, behavior: 'smooth'});
    //window.location.href='#products-feed'
  }
  return (
    <div className=" px-6 relative heightFix mb-24 bg-themeone-section">
      <div className="max-w-screen-xl mx-auto lg:py-10  sm:pt-32 pt-20">
        { false /* disabling until we have quick verification process */ && verified !== true && items.length > 0 /* hack to hide this bar during store-setup-website-preview */ &&
          <div class="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3" role="alert">
            <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/>
            </svg>
            <p>This store is being verified. If you are seeing this message, do not place any order now. Any questions? Email us at <i>contact@ferrypal.com</i></p>
          </div>
        }
        <div className="flex lg:justify-between lg:items-center overflow-hidden p-0.5 lg:flex-row flex-col lg:gap-4 gap-8">
          <Fade>
            <div className="main_heading font-family">
              <h3 className="font-medium sm:text-xl text-fontSize-sm mb-3 text-themeone-subtitle">Welcome to</h3>
              <h1 className="font-semibold xl:text-heading sm:text-6xl xxs:text-5xl text-themeone-head">
                {placeName}
              </h1>
              <div className="flex items-center xl:mt-12 lg:mt-10  sm:mt-8 mt-6 gap-4 flex-wrap">
                <button className="button lg:text-base xl:px-16 px-14" onClick={ourMenu}>
                  Our Menu
                </button>
                <button className="xl:px-16 lg:text-base button-ghost px-14" onClick={viewMore}>
                  Contact Us
                </button>
              </div>
            </div>
          </Fade>
          <Fade>
            <div className="py-12 lg:w-1/2 lg:m-0 lg:max-w-none sm:max-w-lg  max-w-xs  mx-auto">
              <img
                src={imageSrc}
                style={{width: 600, objectFit: 'contain', maxHeight: 400}}
                alt="Store"
                className="rounded-lg"
              />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default Banner;
