import React, {useContext, useEffect, useState} from 'react';
import { logEvent } from 'firebase/analytics';
import Confetti from "react-confetti";

import { StateContext } from '../../../data/state/state-context';
import { analytics } from '../../../lib/firebase';
import { manageStore, setStoreClaimed } from '../../Store/actions';

const Celebrate = ({phoneNumber}) => {
  const [, dispatch] = useContext(StateContext);
  const [show, setShow] = useState(true);

  const setClaimed = () => {
    logEvent(analytics, 'activationdone_clicked');
    dispatch(setStoreClaimed());
  }

  const onClickManageStore = () => {
    logEvent(analytics, 'activationmanagestore_clicked');
    manageStore(phoneNumber);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 10*1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        {show === true && <Confetti width="500" height="500" />}
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">Your digital menu is now active. Congratulations!</h3>
            <div className="mt-4">
              <p className="text-sm text-gray-500">You may close this view now to checkout the shiny new digital menu.</p>
            </div>
            <div className="mt-4">
              <p className="text-sm text-gray-500">
                Also note, you now have access to manage/configure this menu using the <span onClick={onClickManageStore} className="text-blue-500 cursor-pointer">Manage Store</span> option from the menu panel.
              </p>
            </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button onClick={setClaimed} type="button" className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Close</button>
      </div>
    </div>
  );
};

export default Celebrate;
