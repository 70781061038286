import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from '../../Home';
import SearchResult from '../../Search';

const HomeRouter = () => {
  return (
    <Routes>
      <Route path="/search-result" element={<SearchResult />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );
};

export default HomeRouter;
