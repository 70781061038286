import React, { useEffect, useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import About from "../../../components/About/About";
import Banner from "../../../components/Banner/Banner";
import Info from "../../../components/Info/Info";
import Menu from "../../../components/Menu/Menu";
import Testimonials from "../../../components/Testimonials/Testimonials";
import { StateContext } from "../../../data/state/state-context";
import i18n, { languages } from "../../../i18n";
import {
  setQueryParams,
  onChangeLanguage,
  authenticateUsingWaId,
} from "../../Main/actions";

const Items = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const { waId, smartCardScan } = rootState.main;
  const {
    storeId,
    placeName,
    workingHoursText,
    address,
    phoneNumber,
    imageSrc,
    unclaimed,
    verified,
    onlineOrderingSchedule,
    hasOnlineOrdering,
  } = rootState.store;
  const { categories, items } = rootState.items;
  const [searchParams /*, setSearchParams*/] = useSearchParams();
  const navigate = useNavigate();

  // We made a decision to let user sign-in first when scanning smart qr card (mainly for demo purpose, to show customer list to stores)
  // BUG: only new users will be shown in the customer list; If user is already signed in, they will not be taken to login and we're not updating places:{} for them
  const takeToLogin =
    unclaimed !== true &&
    smartCardScan === true &&
    !(rootState.main.userId || sessionStorage.getItem("userId"));

  useEffect(() => {
    const pos = searchParams.get("enable_pos");
    const scard = searchParams.get("scard");
    const waId = searchParams.get("w");
    setQueryParams(dispatch, pos === "true", scard === "1");

    const lng = searchParams.get("lng") ? searchParams.get("lng").trim() : "";
    if (lng && languages.indexOf(lng) !== -1) {
      i18n.changeLanguage(lng).then(() => {
        onChangeLanguage(dispatch, lng);
      });
    }

    if (waId) {
      authenticateUsingWaId(waId);
    } else if (takeToLogin === true) {
      navigate(`/${storeId}/signin`);
    }
  }, [dispatch, searchParams, takeToLogin]);

  return (
    <>
      <Banner
        placeName={placeName}
        items={items}
        imageSrc={imageSrc}
        verified={verified}
      />
      <Menu
        dishes={items}
        categories={categories}
        onlineOrderingSchedule={onlineOrderingSchedule}
        hasOnlineOrdering={hasOnlineOrdering}
      />
      <Testimonials />
      <About />
      <Info
        workingHoursText={workingHoursText}
        address={address}
        phoneNumber={phoneNumber}
      />
    </>
  );
};

export default Items;
