import { signInWithCustomToken } from 'firebase/auth';
import settings from '../../config/settings';
import * as ActionTypes from '../../data/state/action-types';
import { auth } from '../../lib/firebase';

const setQueryParams = (dispatch, enablePOS, smartCardScan) => {
  dispatch({
    type: ActionTypes.QUERY_PARAMS,
    enablePOS,
    smartCardScan,
  })
};

const authenticateUsingWaId = async (waId) => {
  const response = await fetch(
    `${settings.croquetUrl}/authenticate-waid?waId=${waId}`
  );
  if (response.status === 200) {
    const data = await response.json();
    const { token } = data;
    signInWithCustomToken(auth, token);
  }
}

const onChangeLanguage = (dispatch, lng) => {
  sessionStorage.setItem('lng', lng);
  dispatch({
    type: ActionTypes.SET_LANGUAGE,
    lng
  })
};

export {
  setQueryParams,
  authenticateUsingWaId,
  onChangeLanguage
};
