import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import Currency from "react-currency-formatter";
import Skeleton from "react-loading-skeleton";

import OrderItem from "./OrderItem";
import { StateContext } from '../../data/state/state-context';

function OrderDetails() {
  const [rootState, dispatch] = useContext(StateContext);
  const params = useParams();
  const { tripId } = params;
  const { phoneNumber, currency } = rootState.store;
  const { orders } = rootState.orders;
  const order = orders.find((o) => o.id === tripId);
  if(!order){
    // show 404
    return null;
  }

  // add few details for the page
  order.order_status.info = [
    { status: order?.order_status?.current?.status, timestamp: new Date() },
    // { status: 'recieved', timestamp: new Date() },
    // { status: 'in-progress', timestamp: new Date() }
  ]; // log of status
  order.customer_details = {email: 'NA'};
  order.amount_subtotal = 200;
  order.total_details = {amount_shipping: 0};
  const disabled = order?.order_status?.current?.status === 'not-accepted';

  const cancelOrder = () => {
    alert(`To cancel this order please contact the store at +${phoneNumber}`);
  };

  return (
    <div className="heightFix sm:px-6 font-family">
      <div className="border rounded-md max-w-screen-xl  mx-auto md:my-20 my-12 shadow-sm">
        <div className="flex items-center p-5 bg-gray-100 text-sm text-gray-700">
          <div>
            <p className="sm:text-2xl text-xl font-semibold mb-2">
              Order Details
            </p>
            <p className="xs:text-sm text-xs">
              {order ? moment(order?.timestamp).format("llll") : <Skeleton />}
            </p>
          </div>
          {order && (
            <p className="lg:text-xl md:text-lg text-base font-medium whitespace-nowrap  self-end flex-1 text-right text-blue-500">
              {order?.items?.length} items
            </p>
          )}
        </div>
        <div className="p-5 md:p-10 sm:p-8">
          {order ? (
            <>
              <div className="space-y-6 lg:text-lg sm:text-base text-sm">
                <div
                  className={`my-2 p-4 border-2 ${order?.order_status?.current?.status === "cancelled"
                      ? "text-red-500 border-red-500 bg-red-100"
                      : order?.order_status?.current.status === "delivered"
                        ? "text-green-500 border-green-500 bg-green-100"
                        : "text-blue-500 border-blue-500 bg-blue-100"
                    }  rounded-md`}
                >
                  <h1 className="font-semibold mb-4">Order Status</h1>
                  <ul className="space-y-2">
                    {order?.order_status?.info?.map(
                      ({ status, timestamp }, i) => (
                        <li
                          className="flex sm:items-center sm:justify-between sm:flex-row flex-col"
                          key={`orderStatus-${i}`}
                        >
                          <span className="sm:text-sm text-xs font-medium capitalize">
                            {status}
                          </span>
                          <span className="sm:text-sm text-xs">
                            {moment(timestamp).format("llll")}
                          </span>
                        </li>
                      )
                    )}
                  </ul>
                </div>
                {order?.order_status?.current?.status === "cancelled" ? (
                  <p className="my-2 text-xs xs:text-sm text-red-500">
                    * Money will be refunded within 24 hour
                  </p>
                ) : (
                  <></>
                )}
                <p className="whitespace-nowrap font-semibold overflow-x-auto hideScrollBar">
                  ORDER ID -
                  <span className="text-green-500 font-medium ml-2">
                    {order?.id}
                  </span>
                </p>
                <div>
                  <h3 className="font-semibold mb-2  uppercase">Amount</h3>
                  <div className="text-sm text-gray-700">
                    <p>
                      <span className="font-semibold">Subtotal - </span>
                      <Currency
                        quantity={order?.amount_total}
                        currency={currency}
                      />
                    </p>
                    <p className="text-green-500 font-semibold">
                      <span>Shipping - </span>
                      <Currency
                        quantity={order?.total_details?.amount_shipping}
                        currency={currency}
                      />
                    </p>
                    <p className="font-bold text-red-500">
                      <span className="font-semibold">Total - </span>
                      <Currency
                        quantity={(order?.amount_total + order?.total_details?.amount_shipping)}
                        currency={currency}
                      />
                    </p>
                  </div>
                </div>
                <div>
                  <h4 className="font-semibold mb-2  uppercase">Items</h4>
                  {order?.items?.map((item) => (
                    <OrderItem item={item} currency={currency} key={`order-item-${item?._id}`} />
                  ))}
                </div>
                {order?.order_status?.current?.status &&
                  order?.order_status?.current?.status !== "cancelled" &&
                  order?.order_status?.current?.status !== "delivered" ? (
                  <div className="py-4">
                    <button
                      className={`button-red py-2 px-12 capitalize w-full sm:text-base text-sm ${disabled ? "opacity-50" : ""
                        }`}
                      onClick={cancelOrder}
                      disabled={disabled}
                    >
                      Request cancellation
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <Skeleton count={30} />
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
