import React, { useState, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { MenuIcon } from "@heroicons/react/outline";
import { ShoppingCartIcon } from "@heroicons/react/solid";
import SideBarMenu from "../SideBarMenu/SideBarMenu";
import { StateContext } from '../../data/state/state-context';

function HeaderMobile({ storeId }) {
  const [rootState] = useContext(StateContext);
  const navigate = useNavigate();
  const { items } = rootState.cart;
  const { placeName, subscriptionType } = rootState.store;
  const [showSideBar, setShowBar] = useState(false);
  const totalQty = useMemo(()=>{
    return items.reduce((prev, curr) => prev+curr.qty, 0);
  }, [items])
  return (
    <>
      <header className="sticky top-0 inset-x-0 z-30 bg-white text-themeone-color glassmorphism px-6 md:hidden block py-4 transition-all font-family">
        <div className="flex items-center w-full justify-between  mb-4">
          <div className="flex items-center space-x-4">
            <div>
              <MenuIcon className="w-8" onClick={() => setShowBar(true)} />
            </div>
            <div className="flex items-center text-lg font-bold" onClick={() => navigate(`/${storeId}`)}>
              {placeName}
            </div>
          </div>
          {subscriptionType !== 0 && (
            <div
              className="relative cursor-pointer"
              onClick={() => navigate(`/${storeId}/cart`)}
            >
              <ShoppingCartIcon className="xl:w-10 w-9 link" />
              <div className="absolute -top-2 -right-1 rounded-full text-white bg-themeone-dark p-1 flex items-center justify-center text-xs font-extrabold">
                {totalQty}
              </div>
            </div>
          )}
        </div>
      </header>
      <div
        className={`z-40 fixed inset-y-0 left-0 overflow-hidden transition-all duration-300  shadow-2xl  ${showSideBar ? "translate-x-0" : "-translate-x-full"
          }
          `}
      >
        <SideBarMenu closeSideBar={() => setShowBar(false)} />
      </div>
    </>
  );
}

export default HeaderMobile;
