export const storeTheme = {
    default: {
      colors: {
        "background-dark":"#ee4e5e",
        "background-light":"#ec6c7c",
        "head-color":"#1f2937",
        "text-color": "#1f2937",
        "sub-title-color":"#4b5563",
        "content-color":"#1f2937",
        "card-background":"#ffffff",
        "body-background":"#f3f4f6",
        "footer-background":"#1f2937"
      },
      font: {
          fontFamily: {
              // should import the font-family in App.css
              family:["Inter", "Open Sans"]
          },
          size: {
              "sub-font-size":"2rem",
              "heading-font-size":"4rem",
              "content-font-size":".8rem"
          }
      },
    },
    green: {
        colors: {
            "background-dark":"#16a39c",
            "background-light":"#5cb5bc",
            "head-color":"#1f2937",
            "text-color": "#1f2937",
            "sub-title-color":"#4b5563",
            "content-color":"#1f2937",
            "card-background":"#ffffff",
            "body-background":"#f3f4f6",
            "footer-background":"#1f2937"
        },
        font: {
            fontFamily: {
                // should import the font-family in App.css
                family:["Grape Nuts", "cursive"]
            },
            size: {
                "sub-font-size":"2rem",
                "heading-font-size":"4rem",
                "content-font-size":".8rem"
            }
        },
    },
    red: {
      colors: {
        "background-dark":"#792a1e",
        "background-light":"#b45309",
        "head-color":"#1f2937",
        "text-color": "#1f2937",
        "sub-title-color":"#4b5563",
        "content-color":"#1f2937",
        "card-background":"#ffffff",
        "body-background":"#f3f4f6",
        "footer-background":"#1f2937"
      },
      font: {
          fontFamily: {
              // should import the font-family in App.css
              family:["Inter", "Open Sans"]
          },
          size: {
              "sub-font-size":"2rem",
              "heading-font-size":"4rem",
              "content-font-size":".8rem"
          }
      },
    },
    custom: {
      colors: {
        "background-dark":"uses-primaryColor-from-db",
        "background-light":"uses-secondaryColor-from-db",
        "head-color":"#1f2937",
        "text-color": "#1f2937",
        "sub-title-color":"#4b5563",
        "content-color":"#1f2937",
        "card-background":"#ffffff",
        "body-background":"#f3f4f6",
        "footer-background":"#1f2937"
      },
      font: {
          fontFamily: {
              // should import the font-family in App.css
              family:["Inter", "Open Sans"]
          },
          size: {
              "sub-font-size":"2rem",
              "heading-font-size":"4rem",
              "content-font-size":".8rem"
          }
      },
    },
}
