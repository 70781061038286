import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

// Local Modules
import { StateContext } from "../../data/state/state-context";
import { toggleRating, updateStoreRatings } from "../../screens/Store/actions";

export const ratingLabels = {
  1: "Not up to par",
  2: "Not Bad",
  3: "Good",
  4: "Very Good",
  5: "Extremely Good",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${ratingLabels[value]}`;
}

const RateUs = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const { storeId, showRating, ratings } = rootState.store;

  const [hover, setHover] = useState(-1);
  const [rating, setRating] = useState({});

  const userId = rootState.main.userId || sessionStorage.getItem("userId");
  const { displayName } = rootState.main;

  useEffect(() => {
    if (showRating === true) {
      let _rting = {
        value: 3,
        comments: "",
        disabled: false,
        ratedBy: userId,
        displayName,
      };
      const ratingByUser = ratings.find((rtng) => rtng.ratedBy === userId);
      if (ratingByUser !== undefined) {
        _rting = ratingByUser;
      }
      setRating(_rting);
    }
  }, [userId, ratings, showRating, displayName]);

  return (
    <Dialog
      open={showRating}
      onClose={() => {
        toggleRating(dispatch, false);
      }}
      fullWidth
    >
      <DialogTitle sx={{ fontWeight: "600" }}>Rate Us</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingBottom: 2,
            }}
          >
            <Rating
              name="simple-controlled"
              size="large"
              getLabelText={getLabelText}
              value={rating.value}
              onChange={(event, newValue) => {
                setRating({ ...rating, value: newValue });
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            {rating.value !== null && (
              <Box sx={{ ml: 2 }}>
                {ratingLabels[hover !== -1 ? hover : rating.value]}
              </Box>
            )}
          </Box>
        </DialogContentText>
        <Box>
          <Typography variant="h6" gutterBottom>
            Comments :
          </Typography>
          <TextareaAutosize
            style={{ width: "100%", borderWidth: 1, padding: 4, marginTop: 5 }}
            minRows={4}
            aria-label="maximum height"
            placeholder="Please add your valuable feedback"
            value={rating.comments}
            onChange={(event) => {
              setRating({
                ...rating,
                comments: event.target.value,
              });
            }}
          />
        </Box>
        <Box sx={{mt: 5}}>
          <TextField
            id="standard-basic"
            label="Your Name"
            variant="standard"
            value={rating.displayName}
            onChange={(event) => {
              setRating({
                ...rating,
                displayName: event.target.value,
              });
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            toggleRating(dispatch, false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            const isNewRating =
              ratings.find((rtng) => rtng.ratedBy === userId) === undefined;
            let updatedRatings = [];
            if (isNewRating) {
              updatedRatings = [...ratings, rating];
            } else {
              updatedRatings = ratings.map((rtng) =>
                rtng.ratedBy === userId ? rating : rtng
              );
            }
            toggleRating(dispatch, false);
            updateStoreRatings(dispatch, storeId, updatedRatings);
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RateUs;
