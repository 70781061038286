import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { StateContext } from '../../../data/state/state-context';
import { doSearch } from "../actions";
import ItemCard from "./ItemCard";
import SearchForm from "./SearchForm";

const NoItems = () => (
  <div className="w-full text-2xl text-center text-neutral-600 my-10">
    No matches found
  </div>
);

const SearchResult = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const { searchString, items } = rootState.search;

  useEffect(() => {
    doSearch(dispatch, searchString);
  }, [dispatch, searchString]);

  return (
    <>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-1/2 xl:w-1/2 px-8 py-8">
          <div className="flex px-4 py-4 text-4xl text-blue-700 font-sans font-bold">
            <Link to="/">FerryPal</Link>
          </div>
        </div>
        <div class="w-full lg:w-1/2 xl:w-1/2 px-8 py-8">
          <SearchForm />
        </div>
      </div>
      <div class="flex flex-wrap">
        { items.length === 0 ? <NoItems /> : items.map((i) => <ItemCard item={i} />) }
      </div>
    </>
  );
}

export default SearchResult;
