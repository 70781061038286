import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";

// getRecords - reads an array of IDs from a collection concurrently
// Usage eg: getRecords(db.collection('trips'), tripIds)
const getRecords = async (collectionName, ids) => {
  const reads = ids.map((id) => getDoc(doc(db, collectionName, id)));
  const result = await Promise.all(reads);
  return result.map((v) => ({ ...v.data(), id: v.id }));
};

export { getRecords };
