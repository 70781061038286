import React from "react";
import SearchForm from "../../Search/components/SearchForm";

const Home = () => {
  return (
    <>
      <div className="flex px-12 py-12 text-4xl text-blue-700 font-sans font-bold">
        FerryPal
      </div>
      <div className="landingFull">
        <SearchForm />
      </div>
    </>
  );
}

export default Home;
