import * as ActionTypes from '../../data/state/action-types';

const initialState = {
  orders: [
    /* {
      _id: 1,
      id: 1,
      amount_total: 1,
      items: [
        {
          _id: '1.1',
          title: 'one',
          price: 10,
          image: 'url',
          qty: 2,
        }
      ],
      timestamp: new Date(),
      order_status: {current: {status: 'delivered'}},
    } */
  ],
};

const appendOrder = (orders, order) => {
  let found = false;
  const updatedList = orders.map((o) => {
    if(o.id !== order.id){
      return o;
    }

    found = true;
    return order;
  });

  if(found !== true){
    updatedList.push(order);
  }

  return updatedList;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_ORDER:
      return {
        ...state,
        orders: appendOrder(state.orders, action.order),
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
