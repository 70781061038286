import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { setSearchStringAction } from "../actions";
import { StateContext } from '../../../data/state/state-context';

const SearchForm = () => {
  const [, dispatch] = useContext(StateContext);
  const navigate = useNavigate();
  const search = (e) => {
    e.preventDefault();
    const input = document.getElementById('txt-search-food-restaurant');
    if(!input?.value || input.value.trim() === ''){
      return;
    }

    dispatch(setSearchStringAction(input.value))
    navigate('/search-result');
  }

  return (
    <form className="w-full max-w-sm">
      <div className="flex items-center py-2">
        <input className="shadow appearance-none border rounded w-full py-3 px-3 mx-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="txt-search-food-restaurant" type="text" placeholder="Find food or restaurants"  aria-label="search" />
        <button onClick={search} className="flex-shrink-0 bg-blue-700 hover:bg-blue-600 border-blue-700 hover:border-blue-600 text-md font-bold border-4 text-white py-2 px-4 rounded" type="submit">
          Search
        </button>
      </div>
    </form>
  );
}

export default SearchForm;
