import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  HomeIcon,
  LoginIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  XIcon,
  StarIcon,
  AdjustmentsIcon,
} from "@heroicons/react/outline";
import onClickOutside from "react-onclickoutside";
import { StateContext } from "../../data/state/state-context";
import { isStoreAdmin, manageStore, toggleRating } from "../../screens/Store/actions";

function SideBarMenu({ closeSideBar }) {
  const [rootState, dispatch] = useContext(StateContext);
  const navigate = useNavigate();
  const storeId = rootState.store.storeId || sessionStorage.getItem("storeId");
  const userId = rootState.main.userId || sessionStorage.getItem("userId");
  const { phoneNumber } = rootState.main;
  const { placeName, imageSrc, subscriptionType, storeAdmins } = rootState.store;
  SideBarMenu.handleClickOutside = closeSideBar;
  const sideBarClickHandler = (route) => {
    closeSideBar();
    navigate(route);
  };

  return (
    <div className="relative h-full w-full sideBarMenu bg-white px-8 py-6  font-medium md:hidden">
      <div className="flex items-center text-lg font-bold">
        <div className="mr-2">
          <img
            src={imageSrc}
            alt="Diner"
            className="cursor-pointer"
            objectFit="cover"
            style={{width: 32, height: 32, borderRadius: '50%'}}
          />
        </div>
        {placeName}
      </div>
      <div className=" h-0.5 my-4 w-full bg-gray-100"></div>
      <div className="gap-4 flex flex-col">
        {!userId && (
          <div>
            <span
              onClick={() => sideBarClickHandler(`/${storeId}/signin`)}
              className="link inline-flex"
            >
              <LoginIcon className="w-5 mr-6" /> Login
            </span>
          </div>
        )}
        <div>
          <span
            onClick={() => sideBarClickHandler(`/${storeId}`)}
            className="link inline-flex"
          >
            <HomeIcon className="w-5 mr-6" /> Home
          </span>
        </div>
        {userId && subscriptionType !== 0 && (
          <div>
            <span
              onClick={() => sideBarClickHandler(`/${storeId}/orders`)}
              className="link inline-flex"
            >
              <ShoppingBagIcon className="w-5 mr-6" /> Orders
            </span>
          </div>
        )}
        {userId && (
          <div>
            <span
              onClick={() => toggleRating(dispatch, true)}
              className="link inline-flex"
            >
              <StarIcon className="w-5 mr-6" /> Rate Us
            </span>
          </div>
        )}
        {subscriptionType !== 0 && (
          <div>
            <span
              onClick={() => sideBarClickHandler(`/${storeId}/cart`)}
              className="link inline-flex"
            >
              <ShoppingCartIcon className="w-5 mr-6" /> Cart
            </span>
          </div>
        )}
        {isStoreAdmin(storeAdmins, userId) && (
          <div>
            <span
              onClick={() => manageStore(phoneNumber)}
              className="link inline-flex"
            >
              <AdjustmentsIcon className="w-5 mr-6" /> Manage Store
            </span>
          </div>
        )}
      </div>
      <div className="absolute top-2 right-2">
        <XIcon className="w-7" onClick={closeSideBar} />
      </div>
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => SideBarMenu.handleClickOutside,
};

export default onClickOutside(SideBarMenu, clickOutsideConfig);
