import React, { useState, useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Currency from "react-currency-formatter";

import { StateContext } from '../../data/state/state-context';

function Order({ id, amount_total, timestamp, items, status }) {
  const [rootState] = useContext(StateContext);
  const { storeId, phoneNumber, currency } = rootState.store;
  const disabled = status === 'not-accepted';

  const cancelOrder = () => {
    alert(`To cancel this order please contact the store at +${phoneNumber}`);
  };

  return (
    <div>
      <div className="w-full space-x-2">
        <div
          className={`border border-b-0 xs:text-sm text-xs px-4 py-2 rounded-t-md  ${status === "cancelled"
              ? "bg-red-500"
              : status !== "delivered"
                ? "bg-blue-500"
                : "bg-green-500"
            } text-white inline-block capitalize`}
        >
          {status}
        </div>
        {status && status !== "cancelled" && status !== "delivered" ? (
          <button
            className={`button-red border border-b-0 xs:text-sm text-xs px-4 py-2 rounded-t-md rounded-b-none  inline-block  capitalize ${disabled ? "opacity-50" : ""
              }`}
            onClick={cancelOrder}
            disabled={disabled}
          >
            Request cancellation
          </button>
        ) : (
          <></>
        )}
      </div>
      <Link
        to={`/${storeId}/order-details/${id}`}
      >
        <div
          className={`relative border rounded-md rounded-tl-none cursor-pointer hover:shadow-sm bg-themeone-card overflow-hidden ${status && status === "cancelled" ? "opacity-70" : ""
            }`}
          title="Click to view order details"
        >
          <div className="sm:p-6 p-4 bg-gray-100 sm:text-sm text-xs text-gray-600">
            {status && status === "cancelled" ? (
              <p className="mb-2 text-red-500">
                * Money will be refunded within 24 hour
              </p>
            ) : (
              <></>
            )}
            <p className="sm:absolute sm:top-2 sm:right-2 sm:w-56 lg:w-72 truncate text-xs whitespace-nowrap sm:mb-0 mb-2 font-medium">
              ORDER # <span className="text-green-500">{id}</span>
            </p>
            <div className="flex sm:items-center sm:gap-6 gap-1 sm:flex-row flex-col">
              <div className="flex items-center sm:gap-6 gap-4">
                <div>
                  <p className="font-bold text-xs">ORDER PLACED</p>
                  <p>{moment(timestamp).format("DD MMM YYYY")}</p>
                </div>
                <div>
                  <p className="text-xs font-bold">TOTAL</p>
                  <p className="text-xs font-bold text-red-500">
                    <Currency quantity={amount_total} currency={currency} />
                  </p>
                </div>
              </div>
              <p className="lg:text-xl md:text-lg sm:text-base text-sm font-medium  whitespace-nowrap  self-end flex-1 text-right text-blue-500">
                {items?.length} items
              </p>
            </div>
          </div>
          <div className="sm:p-6 p-4">
            <div className="flex space-x-6 overflow-x-auto py-4 hideScrollBar">
              {items?.map((item) => (
                <img
                  key={`item-img-${item?._id}`}
                  className="h-20 object-contain sm:h-32"
                  src={item?.image}
                  alt=""
                  loading="lazy"
                />
              ))}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default Order;
