import { collection, query, where, orderBy,getDocs } from "firebase/firestore";
import { db } from '../../lib/firebase';
import settings from '../../config/settings';

const getCategories = async (enablePOS, storeId) => {
  let categories = [];
  if(enablePOS === true){
    const response = await fetch(
      `${settings.myfireServer}/${storeId}/categories`
    );
    if (response.status === 200) {
      const data = await response.json();
      categories = data.rows.map(({id, name}) => ({ id, name, order: id}));
    }
  } else {
    const q3 = query(collection(db, 'categories'), where('placeId', '==', storeId), orderBy('order'));
    const querySnapshot3 = await getDocs(q3);
    querySnapshot3.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const { categoryId, categoryName, order, discountPercentage, dealValidFrom, dealValidTo } = doc.data();
      categories.push({
        id: categoryId,
        name: categoryName,
        order,
        discountPercentage,
        dealValidFrom,
        dealValidTo,
      });
    });
  }
  return categories;
};

export { getCategories };
