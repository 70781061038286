import React, { useEffect, useState, useCallback } from "react";
import Dish from "../Dish/Dish";
import { AdjustmentsIcon } from "@heroicons/react/outline";
import i18n from "../../i18n";

const checkHasActiveDeal = (categoryOrItem) => {
  if (!categoryOrItem) {
    return false;
  }

  const currTime = new Date().getTime();
  if (
    categoryOrItem.dealValidFrom &&
    categoryOrItem.dealValidTo &&
    typeof categoryOrItem.dealValidFrom.toDate === "function" &&
    typeof categoryOrItem.dealValidTo.toDate === "function" &&
    currTime >= new Date(categoryOrItem.dealValidFrom.toDate()).getTime() &&
    currTime < new Date(categoryOrItem.dealValidTo.toDate()).getTime()
  ) {
    return true;
  } else {
    return false;
  }
};

function Menu({
  dishes,
  categories,
  onlineOrderingSchedule,
  hasOnlineOrdering,
}) {
  const [categoriesWithItems, setCategoriesWithItems] = useState([]);
  const [categoryActive, setCategoryActive] = useState("");
  const [filteredDishes, setFilteredDishes] = useState([]);
  const [hasDeals, setHasDeals] = useState(false);
  const [hasSpecial, setHasSpecial] = useState(false);
  const [isAvailable, setIsAvaible] = useState(true);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [enable, setEnable] = useState(false);
  const filterDishes = useCallback(
    (category) => {
      switch (category) {
        case "deals":
          const categoriesWithDeals =
            categoriesWithItems.filter(checkHasActiveDeal);
          setFilteredDishes(
            dishes.filter((d) => {
              // if the item category has active deal OR the item itself has an active deal, then return true
              return (
                categoriesWithDeals.find((c) => d.category === c.id) ||
                checkHasActiveDeal(d)
              );
            })
          );
          break;

        case "specials":
          setFilteredDishes(dishes.filter((dish) => dish?.ourSpecial === true));
          break;

        case "all":
          setFilteredDishes(dishes);
          break;

        default:
          setFilteredDishes(
            dishes.filter((dish) => dish?.category === category)
          );
          break;
      }
    },
    [dishes]
  );

  const activeCategoryHandler = useCallback(
    (category) => {
      setCategoryActive(category);
      filterDishes(category);
    },
    [filterDishes]
  );
  const convertMinutesToTime = (minutes) => {
    if (minutes === "") return "";
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours < 10 ? "0" : ""}${hours}:${mins < 10 ? "0" : ""}${mins}`;
  };
  useEffect(() => {
    const d = new Date();
    const currentHours = d.getHours();
    const currentMinutes = d.getMinutes();
    const currentTotalMinutes = currentHours * 60 + currentMinutes;
    const { enabled, fromTime, toTime } = onlineOrderingSchedule ? onlineOrderingSchedule[d.getDay()] : {};
    if (hasOnlineOrdering) {
      setEnable(enabled);
      if (enabled) {
        setStartTime(convertMinutesToTime(fromTime));
        setEndTime(convertMinutesToTime(toTime));
        if (currentTotalMinutes >= fromTime && currentTotalMinutes <= toTime) {
          console.log("true", currentTotalMinutes, fromTime, toTime);
          setIsAvaible(true);
        } else {
          setIsAvaible(false);
        }
      }
    } else {
      console.log("false", currentTotalMinutes, fromTime, toTime);
      setIsAvaible(false);
    }
    const cs = categories.filter((c) =>
      dishes.some((d) => d.category === c.id)
    );
    setCategoriesWithItems(cs);
    const _hasDeals =
      cs.some(checkHasActiveDeal) || dishes.some(checkHasActiveDeal);
    const _hasSpecials = dishes.some((dish) => dish?.ourSpecial === true);
    const defaultMenu = _hasSpecials ? "specials" : "all";
    setHasDeals(_hasDeals);
    activeCategoryHandler(_hasDeals ? "deals" : defaultMenu);
    setHasSpecial(_hasSpecials);
  }, [
    dishes,
    categories,
    activeCategoryHandler,
    hasOnlineOrdering,
    onlineOrderingSchedule,
  ]);

  return (
    <div className="w-full pb-20 px-6 mt-10 font-family bg-gray-50" id="menu">
      <h2 className="heading after:bg-themeone-dark py-10 text-themeone-head">
        Menu
      </h2>
      <h3 className="text-center italic text-sm text-gray-500 ">
        {enable
          ? `Store will be taking online orders from ${startTime} to ${endTime}`
          : ""}
      </h3>
      <p className="text-center italic text-sm text-gray-500 lg:mb-24 sm:mb-22 mb-20  mt-4">
        {i18n.t("menu:note")}
      </p>
      <div className="flex items-center w-full max-w-screen-xl sm:mb-20 mb-16 gap-4  mx-auto overflow-x-auto hideScrollBar capitalize text-sm font-medium">
        <div>
          <AdjustmentsIcon className="w-6" />
        </div>
        <div
          className={` py-2 px-6 bg-white text-center rounded hover:bg-themeone-dark hover:text-white transition-all cursor-pointer ease-in-out duration-200 shadow ${
            categoryActive === "all" ? "bg-themeone-dark text-white" : ""
          }`}
          onClick={() => activeCategoryHandler("all")}
        >
          All
        </div>
        {hasDeals && (
          <div
            key={`category-deals`}
            className={`py-2 px-6 bg-white text-center whitespace-nowrap rounded hover:bg-themeone-dark hover:text-white transition-all cursor-pointer ease-in-out duration-200 shadow ${
              categoryActive === "deals" ? "bg-themeone-dark text-white" : ""
            }`}
            onClick={() => activeCategoryHandler("deals")}
          >
            Deals
          </div>
        )}
        {hasSpecial && (
          <div
            key={`category-specials`}
            className={`py-2 px-6 bg-white text-center whitespace-nowrap rounded hover:bg-themeone-dark hover:text-white transition-all cursor-pointer ease-in-out duration-200 shadow ${
              categoryActive === "specials" ? "bg-themeone-dark text-white" : ""
            }`}
            onClick={() => activeCategoryHandler("specials")}
          >
            Our Specials
          </div>
        )}
        {categoriesWithItems?.map((category, i) => (
          <div
            key={`category-${i}`}
            className={`py-2 px-6 bg-white text-center whitespace-nowrap rounded hover:bg-themeone-dark hover:text-white transition-all cursor-pointer ease-in-out duration-200 shadow ${
              categoryActive === category?.id
                ? "bg-themeone-dark text-white"
                : ""
            }`}
            onClick={() => activeCategoryHandler(category?.id)}
          >
            {category?.name}
          </div>
        ))}
      </div>
      <div className="grid grid-flow-row-dense sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-cols-1 mx-auto max-w-screen-xl gap-x-6 gap-y-8">
        {filteredDishes?.map((item, index) => {
          const categObject = categoriesWithItems.find(
            (c) => c.id === item.category
          );
          let hasDealPrice = false;
          let dealPrice = 0;
          let dealEndsAt = null;
          if (checkHasActiveDeal(item)) {
            hasDealPrice = true;
            dealPrice = item.dealPrice;
            dealEndsAt = item.dealValidTo.toDate();
          } else if (checkHasActiveDeal(categObject)) {
            // category deals are discount %
            hasDealPrice = true;
            const { discountPercentage } = categObject;
            dealPrice = item.price - (discountPercentage * item.price) / 100;
            dealEndsAt = categObject.dealValidTo.toDate();
          }

          return (
            <Dish
              key={`dish-${index}`}
              itemId={item.itemId}
              title={item.title}
              price={item.price}
              description={item.description}
              category={item.category}
              image={item.image}
              hasDealPrice={hasDealPrice}
              dealPrice={dealPrice}
              dealEndsAt={dealEndsAt}
              isAvailable={isAvailable}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Menu;
