import * as ActionTypes from "../../data/state/action-types";
import {
  doc,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  updateDoc,
} from "firebase/firestore";

import { db } from "../../lib/firebase";
import { getRecords } from "../../lib/helper";
import { defaultCategories } from "../../data/constants";
import { getCategories } from "../../lib/data-layer/categories";
import { getItems } from "../../lib/data-layer/items";

const setStoreAction = (
  storeId,
  placeName,
  urlHost,
  workingHoursText,
  phoneNumber,
  availableNumbers,
  storeAdmins,
  address,
  imageSrc,
  deliveryOptions,
  hasOwnDelivery,
  hasDunzoDelivery,
  locationInfo,
  currency,
  unclaimed,
  verified,
  theme,
  primaryColor,
  secondaryColor,
  subscriptionType,
  onlineOrderingSchedule,
  hasOnlineOrdering
) => ({
  type: ActionTypes.SET_STORE,
  storeId,
  placeName,
  urlHost,
  workingHoursText,
  phoneNumber,
  availableNumbers,
  storeAdmins,
  address,
  imageSrc,
  deliveryOptions,
  hasOwnDelivery,
  hasDunzoDelivery,
  locationInfo,
  currency,
  unclaimed,
  verified,
  theme,
  primaryColor,
  secondaryColor,
  subscriptionType,
  onlineOrderingSchedule,
  hasOnlineOrdering
});

const setStoreClaimed = () => ({
  type: ActionTypes.STORE_CLAIMED,
});

// hack - setShowPreview just sets the store as claimed temporarily (when reloaded it will get reset)
const setShowPreview = () => ({
  type: ActionTypes.STORE_CLAIMED,
});

const loadItemsAction = (items) => ({
  type: ActionTypes.LOAD_ITEMS,
  items,
});

const loadCategoriesAction = (categories) => ({
  type: ActionTypes.LOAD_CATEGORIES,
  categories,
});

const sortItemsByCategoryOrder = (items, categories) => {
  items.sort((a, b) => {
    const categA = categories.find(({ id }) => id === a.category);
    const categB = categories.find(({ id }) => id === b.category);
    if (categA?.order && categB?.order) {
      return categA.order - categB.order;
    }
    return 0;
  });
  return items;
};

const loadStoreInfo = async (dispatch, enablePOS, storeId) => {
  const [
    {
      placeName,
      urlHost,
      workingHoursText,
      phoneNumber,
      availableNumbers,
      storeAdmins,
      address,
      imageSrc,
      items: placeItems,
      deliveryOptions,
      hasOwnDelivery,
      hasDunzoDelivery,
      locationInfo,
      currency,
      unclaimed,
      verified,
      theme,
      primaryColor,
      secondaryColor,
      ratings,
      subscriptionType,
      onlineOrderingSchedule,
      hasOnlineOrdering
    },
  ] = await getRecords("places", [storeId]);
  dispatch(
    setStoreAction(
      storeId,
      placeName,
      urlHost,
      workingHoursText,
      phoneNumber,
      availableNumbers,
      storeAdmins,
      address,
      imageSrc,
      deliveryOptions,
      hasOwnDelivery,
      hasDunzoDelivery,
      locationInfo,
      currency,
      unclaimed,
      verified,
      theme,
      primaryColor,
      secondaryColor,
      subscriptionType,
      onlineOrderingSchedule,
      hasOnlineOrdering
    )
  );
  dispatch(dispatchStoreRatings(ratings));

  const categories = await getCategories(enablePOS, storeId);
  dispatch(
    loadCategoriesAction(categories.length > 0 ? categories : defaultCategories)
  );

  let availableItems = [];
  if (placeItems && Object.keys(placeItems).length > 0) {
    const items = await getItems(enablePOS, storeId, placeItems);
    availableItems = items
      .filter(({ available }) => available === true) // TODO: filter it in the query, before reading from db
      .map(
        ({
          id,
          itemName,
          description,
          category,
          ourSpecial,
          price,
          imageSrc,
          dealPrice,
          dealValidFrom,
          dealValidTo,
        }) => ({
          itemId: id,
          title: itemName,
          description,
          category,
          ourSpecial,
          price,
          image: imageSrc,
          frequency: placeItems[id],
          dealPrice,
          dealValidFrom,
          dealValidTo,
        })
      )
      .sort((a, b) => b.frequency - a.frequency);
  }
  dispatch(
    loadItemsAction(sortItemsByCategoryOrder(availableItems, categories))
  );
};

const dispatchStoreRatings = (ratings = []) => ({
  type: ActionTypes.UPDATE_STORE_RATINGS,
  ratings,
});

const toggleRating = (dispatch, showRating) => {
  dispatch({
    type: ActionTypes.TOGGLE_STORE_RATING,
    showRating,
  });
};

const updateStoreRatings = async (dispatch, placeId, ratings) => {
  try {
    const placeRef = doc(db, "places", placeId);
    await updateDoc(placeRef, {
      ratings,
    });
  } catch (error) {
    console.log(error.message);
  }

  dispatch(dispatchStoreRatings(ratings));
};

const isStoreAdmin = (storeAdmins, userId) =>
  Array.isArray(storeAdmins) && storeAdmins.includes(userId);

const manageStore = (phoneNumber) => {
  const manageStoreUrl = `https://store.ferrypal.com/phone-login?p=${encodeURIComponent(
    phoneNumber
  )}`;
  window.open(manageStoreUrl, "_blank");
};

export {
  setShowPreview,
  setStoreClaimed,
  loadStoreInfo,
  toggleRating,
  updateStoreRatings,
  isStoreAdmin,
  manageStore,
};
