import React, { useState, useContext, useMemo,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Currency from "react-currency-formatter";
import CartDish from "../../../components/CartDish/CartDish";
import { StateContext } from '../../../data/state/state-context';
import { emptyCartAction, placeOrder, setCartDetails } from '../actions';
import { db } from "../../../lib/firebase";
import { doc, getDoc } from "firebase/firestore";
import PickupOrDelivery from "./PickupOrDelivery";
import Pickup from "./Pickup";
import Delivery from "./Delivery";
import { toast } from 'react-toastify';
import { isEmpty } from "@firebase/util";
import { convertDistance } from 'geolib';
import settings from '../../../config/settings';

const PlaceOrder = ({ proceedToCheckout, clearCart, userId, storeId, notes, items, pickdel, pickupValue,deliveryValue,userDeliveryLocations }) => {
  const navigate = useNavigate();
  const _placeOrder = async () => {
    if(pickdel === "delivery" && isEmpty(deliveryValue.address)){
      return toast.error("Select a delivery address");
    }else{
      if(pickdel === "pickup"){
        await placeOrder(userId, storeId, notes, items, pickdel, pickupValue,deliveryValue,userDeliveryLocations);
      }
      else if(pickdel === "delivery"){
        await placeOrder(userId, storeId, notes, items, pickdel,pickupValue, deliveryValue,userDeliveryLocations);
      }
      clearCart();
      navigate(`/${storeId}`);
    }
  };

  return userId ? (
      <button
      role="link"
      className="button mt-6 lg:text-lg text-base py-2"
      onClick={_placeOrder}
      >
      Place your order
      </button>
    ) : (
      <button
      role="link"
      className="button mt-6 lg:text-lg text-base py-2"
      onClick={proceedToCheckout}
      >
      Proceed to checkout
      </button>
  );
};

const CartContent = ({items, clearCart}) => (
  <div className="my-6 shadow rounded-md">
    <div className="flex flex-col md:p-8  p-6  bg-white">
      <h1 className="sm:text-2xl text-xl  font-semibold border-b-2 border-gray-200 pb-4 text-gray-700">
        Shopping Cart
      </h1>
      <div className="flex justify-between items-center py-6">
        <span className="font-medium text-lg text-themeone-dark">
          Items
          <span className="font-semibold text-xl ml-2">
            {items?.length}
          </span>
        </span>
        <button
          className={`button-red py-2 px-8 xs:px-10 ${false ? "opacity-50" : "" }`}
          onClick={clearCart}
          disabled={false}
          >
          Empty Cart
        </button>
      </div>
      {items.map((item, i) => {
        return (
          <CartDish
            key={`cart-dish-${item?.itemId}`}
            itemId={item?.itemId}
            title={item?.title}
            price={item?.price}
            description={item?.description}
            category={item?.category}
            image={item?.image}
            qty={item?.qty}
            border={i !== items?.length - 1}
            disabled={false}
          />
        );
      })}
    </div>
  </div>
);

const CartEmpty = () => (
  <div className="flex items-center justify-center w-full  px-6 lg:py-20 sm:py-10 py-4">
    <div className="text-center md:max-w-none sm:w-auto mx-auto max-w-xs w-4/5">
      <img
        src="/img/empty_cart.svg"
        alt=""
        width={350}
        height={350}
        objectFit="contain"
      />
      <h3 className="lg:text-2xl text-xl font-medium mt-4">
        Your Cart is Empty
      </h3>
    </div>
  </div>
);

const Cart = () => {
  const navigate = useNavigate();
  const [rootState, dispatch] = useContext(StateContext);
  const userId = rootState.main.userId || sessionStorage.getItem('userId');
  const { storeId, address, deliveryOptions, hasOwnDelivery, hasDunzoDelivery, locationInfo, currency } = rootState.store;
  const { items } = rootState.cart;
  const [totalAmout, setTotalAmout] = useState(0);
  const [notes, setNotes] = useState('');
  const [pickdel, setPickdel] = useState("pickup");
  const [selectedAddress, setSelectedAddress] = useState({});
  const [pickupValue, setPickupValue] = useState({requestedTime:"ASAP"});
  const [deliveryValue, setDeliveryValue] = useState({
    requestedTime: "ASAP",
    deliveryCharge: 0,
    address: "",
    locationInfo: {
      geocodedAddress: "",
      lat: "",
      lng: ""
    }
  });
  const [userDeliveryLocations, setUserDeliveryLocations] = useState([
    /*
    address,
    lastDeliveryDate,
    locationInfo: { geocodedAddress, lat, lng }
    */
  ]);
  const totalQty = useMemo(()=>{
    return items.reduce((prev, curr) => prev+curr.qty, 0);
  }, [items]);
  const clearCart = () => { dispatch(emptyCartAction()) };

  const hasDelivery = hasOwnDelivery === true || hasDunzoDelivery === true;
  const checkPickOrDel = (event) => {
    setPickdel(event.target.value);
  }

  const promisifiedGetDistanceMatrix = params => new Promise(resolve => {
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(params, (response, status) => {
      resolve({ response, status })
    })
  })

  const getElementsDistanceDuration = ({ response, status }) => {
    if (status !== 'OK') return console.log("😱 Error: ", status);
    let elements = {};

    let result = response.rows[0].elements[0];
    let distance = result.distance.value;
    let distanceText = result.distance.text;
    let duration = result.duration.value;
    let durationText = result.duration.text;
    elements= {distance,distanceText,duration,durationText};
    return elements
  }

  let ownDeliveryOptionThatFits = null; //// need to use state or useRef or something here
  const canDoOwnDelivery = (distanceKm) => {
    if(!hasOwnDelivery) {
      return false;
    }

    ownDeliveryOptionThatFits = deliveryOptions.find((x) => {
      // x.distance = elementsDistanceDuration;
      return distanceKm <= x.distanceUpto;
    });

    return !!ownDeliveryOptionThatFits;
  }

  let dunzoEstimate = 0;
  const canDoDunzoDelivery = async ({lat, lng}, distanceKm, requestedTime) => {
    if(!hasDunzoDelivery ||  distanceKm > 100  /* just for a safeguard */ ) {
      return false;
    }

    const response = await fetch(
      `${settings.croquetUrl}/dunzo-quote?storeId=${storeId}&lat=${lat}&lng=${lng}&requestedTime=${requestedTime}`
    );
    if (response.status === 200) {
      const data = await response.json();
      dunzoEstimate = data.estimatedPrice;
      return true;
    }

    return false;
  };

  const canDoDelivery = async ({lat, lng}, requestedTime) => {
    const origin = {lat:locationInfo.lat,lng:locationInfo.lng};
    const destination = {lat, lng};
    const elementsDistanceDuration = await promisifiedGetDistanceMatrix({
      origins: [origin],
      destinations: [destination],
      travelMode: window.google.maps.TravelMode.DRIVING,
      unitSystem: window.google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
    })
    .then(getElementsDistanceDuration)
    .catch(() => { return null; /* exception - return null to be handled below */ });

    if(!elementsDistanceDuration) {
      return false;
    }

    const distanceKm = convertDistance(elementsDistanceDuration.distance,'km');
    const canDeliver = canDoOwnDelivery(distanceKm) || await canDoDunzoDelivery(destination, distanceKm, requestedTime);
    return canDeliver;
  }

  const calculateDeliveryCharge = async () => {
    if(!ownDeliveryOptionThatFits){
      // if hasDunzo, dunzo quote
      // else - no delivery - we shouldn't have come this far - disable order button?
      return dunzoEstimate;
    }

    if(totalAmout >= Number(ownDeliveryOptionThatFits?.freeDeliveryFor)){
      return 0;
    } else {
      return Number(ownDeliveryOptionThatFits?.deliveryCharge);
    }
  };

  const onChangeAddress = async (deliveryLocation) => {
    const canDeliver = await canDoDelivery(deliveryLocation.locationInfo, deliveryValue.requestedTime);
    if(canDeliver) {
      const deliveryCharge = await calculateDeliveryCharge();
      setDeliveryValue({...deliveryValue, ...deliveryLocation, deliveryCharge});
      setSelectedAddress(deliveryLocation);
    } else {
      toast.error(`Delivery not available to this location`);
    }
  }

  const setAddress = async (deliveryLocation) => {
    setUserDeliveryLocations([...userDeliveryLocations, deliveryLocation]);
    await onChangeAddress(deliveryLocation);
  }

  const onDateChange = async (event)=> {
    const requestedTime = Number(new Date(event.target.value));
    const canDeliver = await canDoDelivery(deliveryValue.locationInfo, requestedTime);
    if(!canDeliver){
      return toast.error(`Delivery not available for the requested time`);
    }

    if(event.target.name === "pickupdate"){
      setPickupValue({ requestedTime });
    }
    else{
      setDeliveryValue({...deliveryValue, requestedTime});
    }
  }

  const proceedToCheckout = () => {
    dispatch(setCartDetails({
      notes,
      pickdel,
      selectedAddress,
      pickupValue,
      deliveryValue,
      userDeliveryLocations,
    }));
    navigate(`/${storeId}/signin`);
  };

  useEffect(() => {
    if(userId){
      const user_ref =  doc(db, "users",userId);
      getDoc(user_ref).then(docSnap => {
        if (docSnap.exists()) {
          const {deliveryLocations} = docSnap.data();
          setUserDeliveryLocations(deliveryLocations ? deliveryLocations : []);
        }
      });
    }
  },[userId]);

  useEffect(()=>{
    if(!isEmpty(selectedAddress?.deliveryOption?.freeDeliveryFor)){
      let deliveryCharge = 0;
      if(totalAmout >= Number(selectedAddress?.deliveryOption?.freeDeliveryFor)){
        deliveryCharge = 0;
      }else{
        deliveryCharge = Number(selectedAddress?.deliveryOption?.deliveryCharge);
      }
      setDeliveryValue({...deliveryValue, deliveryCharge});
    }

    let total = 0;
    for(const item of items){
      total += (item?.qty * item?.price);
    }
    setTotalAmout(total);
  },[items]);

  return (
    <div className="bg-themeone-body py-10 md:px-6 heightFix font-family">
    <main className="max-w-screen-xl mx-auto">
    {items?.length ? <CartContent items={items} clearCart={clearCart} />  : <CartEmpty />}
    {items?.length &&
      <>
        <div className="my-3 shadow rounded-md">
          <div className="flex flex-col md:p-4  p-3  bg-white">
            <div className="form-group">
              <textarea
                className="px-2 py-2 form-control"
                placeholder="Add any notes to the order here"
                style={{width: '100%'}}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
          </div>
        </div>
        <PickupOrDelivery hasDelivery={hasDelivery} onChange={checkPickOrDel} />
        { pickdel === "pickup" ? (
            <Pickup address={address} onDateChange={onDateChange} />
          ) : (
            <Delivery
              deliveryOptions={deliveryOptions}
              userDeliveryLocations={userDeliveryLocations}
              setAddress={setAddress}
              onDateChange={onDateChange}
              onChangeAddress={onChangeAddress}
              selectedAddress={selectedAddress}
              locationInfo={locationInfo}
            />
          )
        }
        <div className="flex flex-col bg-white md:p-10  py-8 px-6 shadow-md rounded-md md:text-xl sm:text-lg text-base my-10">
          <h2 className="whitespace-nowrap font-semibold overflow-x-auto hideScrollBar">
            Total ({totalQty} items) :
            <span className="font-bold text-themeone-dark mx-2">
              <Currency quantity={totalAmout} currency={currency} />
            </span>
            <span className="text-md">
              + Applicable tax
            </span>
          </h2>
          {
            hasDelivery &&
            <p className="whitespace-nowrap text-sm font-normal overflow-x-auto hideScrollBar">
              Delivery cost:
              <span className="font-normal text-sm text-grey-500 mx-2">
                <Currency quantity={pickdel === "pickup"?0:deliveryValue.deliveryCharge} currency={currency} />
              </span>
            </p>
          }
          <PlaceOrder
            proceedToCheckout={proceedToCheckout}
            clearCart={clearCart}
            userId={userId}
            storeId={storeId}
            notes={notes}
            items={items}
            pickdel={pickdel}
            pickupValue={pickupValue}
            deliveryValue={deliveryValue}
            userDeliveryLocations={userDeliveryLocations}
          />
        </div>
        </>
      }
      </main>
    </div>
  );
}

export default Cart;
