import i18n from "../../i18n";

function About() {
  return (
    <div className="px-6 lg:py-20 sm:py-14 py-10 font-family" id="about">
      <div className="max-w-screen-xl mx-auto">
        <h2 className="heading after:bg-themeone-dark text-themeone-head">{i18n.t('about:title')}</h2>
        <p className="mt-12 lg:max-w-3xl sm:max-w-xl max-w-sm mx-auto text-center sm:text-content text-themeone-content text-sm">
          {i18n.t('about:description')}
        </p>
      </div>
    </div>
  );
}

export default About;
