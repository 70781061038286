import React from "react";

const Pickup = ({address, onDateChange}) => {
  return (
    <div className="flex flex-col md:p-4  p-3  bg-white">
    <div class="grid sm:grid-cols-4 gap-6">
        <div className="relative flex flex-col">
            <p>
            <label for="address" className="text-lg my-2  mb-4 line-clamp-3 text-gray-900">Pickup address</label>
                <span id="address" className="text-gray-900">{address}</span>
            </p>
        </div>
        <div className="relative flex flex-col">
          <label for="pickupdate" className="text-lg my-2  mb-4 line-clamp-3  text-gray-900">Pickup date</label>
            <input
              id="pickupdate"
              name="pickupdate"
              className="shadow appearance-none border rounded w-half py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
              type={"datetime-local"}
              onChange={onDateChange}
            />
            <p class="mt-2 text-grey-200 text-xs">
                Leave it blank if you want to pickup it up as soon as possible
            </p>
        </div>
      </div>
    </div>
  );
}

export default Pickup;
