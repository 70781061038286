const getCountryCode = () => {
  // temporarily guess country code based on time zone (won't work for countries with DST)
  const offset = (new Date()).getTimezoneOffset();
  switch (offset) {
    case -330:
      return 'IN';
    case -240:
      return 'AE';
    default:
      return 'US';
  }
};

export { getCountryCode };
