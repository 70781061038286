import { createContext } from 'react';
import mainReducer from '../../screens/Main/reducers';
import storeReducer from '../../screens/Store/reducers';
import itemsReducer from '../../screens/Items/reducers';
import cartReducer from '../../screens/Cart/reducers';
import ordersReducer from '../../screens/Orders/reducers';
import searchReducer from '../../screens/Search/reducers';
import { usePersistedReducer } from './persisted-reducer';

const combineReducers = (reducers) => {
  return (children, action) => {
    const newState = {};
    for (const key in reducers) {
      newState[key] = reducers[key](children[key], action);
    }
    return newState;
  };
};

const state = {
  main: mainReducer.initialState,
  store: storeReducer.initialState,
  items: itemsReducer.initialState,
  cart: cartReducer.initialState,
  orders: ordersReducer.initialState,
  search: searchReducer.initialState,
};

const reducers = combineReducers({
  main: mainReducer.reducer,
  store: storeReducer.reducer,
  items: itemsReducer.reducer,
  cart: cartReducer.reducer,
  orders: ordersReducer.reducer,
  search: searchReducer.reducer,
});

const getCombinedReducer = () => {
  if (process.env.NODE_ENV === 'production') {
    return reducers;
  }

  const logger = require('use-reducer-logger').default;
  return logger(reducers);
};

const useRootReducer = () => {
  const cr = getCombinedReducer();
  const storageKey = '__poster_state';
  return usePersistedReducer(cr, state, storageKey);
};

const StateContext = createContext();

export { useRootReducer, StateContext };
