import React, { useContext, useEffect } from "react";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { FloatingWhatsApp } from 'react-floating-whatsapp-button'
import 'react-floating-whatsapp-button/dist/index.css'

import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import HeaderMobile from "../Header/HeaderMobile";
import { StateContext } from '../../data/state/state-context';
import { auth } from "../../lib/firebase";
import * as ActionTypes from '../../data/state/action-types';
import { loadStoreInfo } from '../../screens/Store/actions';
import { storeTheme } from '../../theme'
import RateUs from "../Testimonials/Rateus";
import { getRecords } from "../../lib/helper";
import ClaimStore from "../../screens/ClaimStore";

const setUserAction = (userId, displayName, phoneNumber) => {
  return {
    type: ActionTypes.SET_USER,
    userId,
    displayName,
    phoneNumber,
  };
};

const getUrlStoreId = () => {
  const path = window.location.pathname;
  const pathParts = path.split('/');
  const storeIndex = pathParts.indexOf('stores');
  const storeId = pathParts[storeIndex + 1]; // storeid passed to the url
  return storeId;
}

function Layout({ children }) {
  const [rootState, dispatch] = useContext(StateContext);
  const storeId = getUrlStoreId();
  const { enablePOS } = rootState.main;
  const { theme, primaryColor, secondaryColor, unclaimed, placeName, urlHost, showRating } = rootState.store;
  const storeHandle = urlHost? urlHost.split('.')[0] : '';
  const fpNumber = '919021195875';
  useEffect(() => {
    const getUserData = async (dispatch, userId) => {
      try {
        const [user] = await getRecords("users", [userId]);
        console.log('user details ', user);
        dispatch(setUserAction(userId, user.displayName, user.phoneNumber));
      } catch (error) {
        dispatch(setUserAction(userId, '', ''));
      }
    };

    onAuthStateChanged(auth, (user)=>{
      if(user) {
        console.log('We have a user!!!!');
        sessionStorage.setItem('userId', user.uid);
        getUserData(dispatch, user.uid);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    console.log('storeId ==>', storeId);
    sessionStorage.setItem('storeId', storeId);
    loadStoreInfo(dispatch, enablePOS, storeId);
  }, [dispatch, enablePOS, storeId]);

  useEffect(() => {
    var bodyStyles = document.documentElement.style;
    if(theme === 'custom'){
      bodyStyles.setProperty('--themeone-dark', primaryColor?primaryColor:storeTheme["default"].colors["background-dark"]);
      bodyStyles.setProperty('--themeone-light', secondaryColor?secondaryColor:storeTheme["default"].colors["background-light"]);
    } else {
      bodyStyles.setProperty('--themeone-dark', storeTheme[theme?theme:"default"].colors["background-dark"]);
      bodyStyles.setProperty('--themeone-light', storeTheme[theme?theme:"default"].colors["background-light"]);
    }
      bodyStyles.setProperty('--themeone-color', storeTheme[theme?theme:"default"].colors["text-color"]);
      bodyStyles.setProperty('--themeone-head', storeTheme[theme?theme:"default"].colors["head-color"]);
      bodyStyles.setProperty('--themeone-subtitle', storeTheme[theme?theme:"default"].colors["sub-title-color"]);
      bodyStyles.setProperty('--themeone-content', storeTheme[theme?theme:"default"].colors["content-color"]);
      bodyStyles.setProperty('--themeone-body', storeTheme[theme?theme:"default"].colors["body-background"]);
      bodyStyles.setProperty('--themeone-card', storeTheme[theme?theme:"default"].colors["card-background"]);
      bodyStyles.setProperty('--themeone-footer', storeTheme[theme?theme:"default"].colors["footer-background"]);
      bodyStyles.setProperty('--font-sub', storeTheme[theme?theme:"default"].font.size["sub-font-size"]);
      bodyStyles.setProperty('--font-heading', storeTheme[theme?theme:"default"].font.size["heading-font-size"]);
      bodyStyles.setProperty('--font-content', storeTheme[theme?theme:"default"].font.size["content-font-size"]);
      bodyStyles.setProperty('--font-family', storeTheme[theme?theme:"default"].font.fontFamily["family"]);
  },[theme])
  return placeName ? (
    <div className="layout bg-themeone-body font-family">
      <Header storeId={storeId} />
      <HeaderMobile storeId={storeId} />
      {children}
      <FloatingWhatsApp
        phone={fpNumber}
        headerTitle="Get Menu on the Phone"
        message={`Hi ${storeHandle}`}
        size="48px"
        popupMessage={`Send 'Hi ${storeHandle}' to +${fpNumber}`}
        autoOpenTimeout={1800*1000}
      />
      {showRating && <RateUs />}
      <Footer storeId={storeId} />
      {unclaimed === true && <ClaimStore />}
    </div>
  ) : (
    <div class="flex justify-center items-center h-screen w-screen bg-themeone-body	">
      <svg role="status" class="inline mr-2 sm:w-8 sm:h-8 lg:w-16 lg:h-16 h-8 w-8 text-gray-200 animate-spin dark:text-gray-600 fill-themeone-dark" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
      <span>Loading...</span>
    </div>
  );
}

export default Layout;
