import { collection, doc, addDoc, updateDoc } from "firebase/firestore";
import * as ActionTypes from '../../data/state/action-types';
import { db } from "../../lib/firebase";

const emptyCartAction = () => ({
  type: ActionTypes.EMPTY_CART,
});

const setCartDetails = (details) => ({
  type: ActionTypes.CART_DETAILS,
  details,
});

const placeOrder = async (userId, placeId, notes, items, pickDel, pickupParam , deliveryParam, userLocations) => {
  const tripRef = await addDoc(collection(db, "trips"), {
    userId,
    placeId,
    when: new Date(),
    notes,
    notifyList: [],
    orderItems: {},
    createdAt: new Date(),
    pickedUpAt: null,
    isOrderDelivery: pickDel === "delivery"?true:false,
    orderPickup: pickDel === "pickup"?pickupParam:{},
    orderDelivery: pickDel === "delivery"?deliveryParam:{}
  });

  const tripId = tripRef.id;
  const orderItemIds = [];
  for (const item of items) {
    const { itemId, price, qty: quantity } = item;
    const orderItemRef = await addDoc(collection(db, "order_item"), {
      userId,
      itemId,
      price,
      quantity,
      quantityType: 'Piece',
      notes: '',
      tripId,
      assignedTo: userId, //self
      bought: false,
      boughtTime: null,
    });

    orderItemIds.push(orderItemRef.id);
  }

  const orderItemsOfNotifyId = `orderItems.self`;
  await updateDoc(tripRef, {
    [orderItemsOfNotifyId]: orderItemIds,
  });

  if(pickDel === "delivery"){
    const users_ref =  doc(db, "users", userId);
    await updateDoc(users_ref, {
      deliveryLocations: userLocations,
    });
}
};

export { emptyCartAction, setCartDetails, placeOrder };
