import { collection, query, getDocs, where, orderBy, startAt, endAt } from "firebase/firestore";

import * as ActionTypes from '../../data/state/action-types';
import { db } from "../../lib/firebase";
import { getRecords } from "../../lib/helper";

const setSearchStringAction = (searchString) => ({
  type: ActionTypes.SET_SEARCH_STRING,
  searchString,
});

const loadSearchResultAction = (items) => ({
  type: ActionTypes.LOAD_SEARCH_RESULT,
  items,
});

const doSearch = async (dispatch, searchString) => {
  if(!searchString){
    return;
  }
  // BUG: the query will get lot of unexpected result (we are getting everything between uppercase and lowercase)
  // TODO: switch to some other full text index db for searching
  const q = query(
    collection(db, "items"),
    where('available', '==', true),
    orderBy('itemName'),
    startAt(searchString.toUpperCase()),
    endAt(searchString.toLowerCase()+'\uf8ff')
  );

  const querySnapshot = await getDocs(q);
  let items = [];
  const places = new Set()
  querySnapshot.forEach((doc) => {
    const {placeId, itemName, description, category, price, imageSrc} = doc.data();
    const reg = new RegExp(searchString, 'i');
    if([itemName, description, category].some((str) => reg.test(str)) === true){
      items.push({ placeId, itemId: doc.id, title: itemName, price, description, category, image: imageSrc });
      places.add(placeId);
    }
  });

  for (let placeId of places) {
    const [{placeName, phoneNumber, address, imageSrc }] = await getRecords('places', [placeId]);
    items = items.map((i) => i.placeId !== placeId ? i : {...i, placeName, phoneNumber, address, storeImage: imageSrc});
  }
  dispatch(loadSearchResultAction(items));
}

export { setSearchStringAction, doSearch };
