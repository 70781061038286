import * as ActionTypes from '../../data/state/action-types';

const initialState = {
  userId: '',
  displayName: '',
  phoneNumber: '',
  enablePOS: false,
  smartCardScan: false,
  lng: 'en', // 'en', 'ml', 'ta' , etc
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return {
        ...state,
        userId: action.userId,
        displayName: action.displayName,
        phoneNumber: action.phoneNumber,
      };
    case ActionTypes.QUERY_PARAMS:
      return {
        ...state,
        enablePOS: action.enablePOS,
        smartCardScan: action.smartCardScan,
      }
    case ActionTypes.SET_LANGUAGE:
      return {
        ...state,
        lng: action.lng,
      }
    default:
      return state;
  }
};

export default { initialState, reducer };
