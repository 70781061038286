import {
    ClockIcon,
    LocationMarkerIcon,
    PhoneIcon,
} from "@heroicons/react/solid";
import i18n from "../../i18n";

function Info({ workingHoursText, address, phoneNumber }) {
    return (
        <div className="px-6 py-12 font-family">
            <div className="flex justify-evenly mx-auto max-w-screen-lg flex-wrap sm:flex-row flex-col">
                <div className="flex flex-col py-2 lg:w-1/3 items-center">
                    <div className="pb-4">
                        <ClockIcon className="lg:w-12 w-10 text-themeone-dark mx-auto " />
                    </div>
                    <h3 className="text-center font-semibold">{workingHoursText ? workingHoursText : '11am-4pm, 6:30pm-11pm'}</h3>
                    <h4>{i18n.t('info:working_hours')}</h4>
                </div>
                <div onClick={()=>{window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`);}} className="flex flex-col py-2 lg:w-1/3 items-center">
                    <div className="pb-4">
                        <LocationMarkerIcon className="lg:w-12 w-10 text-themeone-dark mx-auto" />
                    </div>
                    <h3 className="text-center font-semibold">{address}</h3>
                    <h4>{i18n.t('info:get_directions')}</h4>
                </div>
                <div onClick={()=>{window.open(`tel:${phoneNumber}`);}} className="flex flex-col py-2 lg:w-1/3 items-center">
                    <div className="pb-4">
                        <PhoneIcon className="lg:w-12 w-10 text-themeone-dark mx-auto" />
                    </div>
                    <h3 className="text-center font-semibold">+{phoneNumber}</h3>
                    <h4>{i18n.t('info:call_now')}</h4>
                </div>
            </div>
        </div>
    );
}

export default Info;
