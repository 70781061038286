import * as ActionTypes from "../../data/state/action-types";

const initialState = {
  storeId: "",
  placeName: "",
  urlHost: "",
  subscriptionType: 0,
  workingHoursText: "",
  phoneNumber: "",
  availableNumbers: [],
  storeAdmins: [],
  address: "",
  imageSrc: "",
  currency: "INR",
  unclaimed: false,
  verified: true,
  theme: "",
  primaryColor: "",
  secondaryColor: "",
  ratings: [
    // {
    // value: 1,
    // comments: '',
    // disabled: false,
    // ratedBy: 'userId'
    // }
  ],
  showRating: false,
  deliveryOptions: [
    //  {
    //    deliveryCharge: 5,
    //    distanceUpto: 3,
    //    freeDeliveryFor: 100
    //  }
  ],
  hasOwnDelivery: false,
  hasDunzoDelivery: false,
  onlineOrderingSchedule: {},
  hasOnlineOrdering: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_STORE:
      return {
        ...state,
        storeId: action.storeId,
        placeName: action.placeName,
        urlHost: action.urlHost,
        workingHoursText: action.workingHoursText,
        phoneNumber: action.phoneNumber,
        availableNumbers: action.availableNumbers,
        storeAdmins: action.storeAdmins,
        address: action.address,
        imageSrc: action.imageSrc,
        deliveryOptions: action.deliveryOptions,
        hasOwnDelivery: action.hasOwnDelivery,
        hasDunzoDelivery: action.hasDunzoDelivery,
        locationInfo: action.locationInfo,
        currency: action.currency,
        unclaimed: action.unclaimed,
        verified: action.verified,
        theme: action.theme,
        primaryColor: action.primaryColor,
        secondaryColor: action.secondaryColor,
        subscriptionType: action.subscriptionType,
        onlineOrderingSchedule: action.onlineOrderingSchedule,
        hasOnlineOrdering: action.hasOnlineOrdering,
      };
    case ActionTypes.STORE_CLAIMED:
      return {
        ...state,
        unclaimed: false,
      };
    case ActionTypes.TOGGLE_STORE_RATING:
      return {
        ...state,
        showRating: action.showRating,
      };
    case ActionTypes.UPDATE_STORE_RATINGS:
      return {
        ...state,
        ratings: action.ratings,
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
