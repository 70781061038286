import * as ActionTypes from '../../data/state/action-types';

const initialState = {
  searchString: '',
  items: [
    /* { placeId, itemId, title, price, description, category, image, placeName, phoneNumber, address, storeImage } */
  ],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString,
      };
    case ActionTypes.LOAD_SEARCH_RESULT:
      return {
        ...state,
        items: action.items,
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
