import React, { useContext } from "react";
import Currency from "react-currency-formatter";
import Fade from "react-reveal/Fade";
import { toast } from "react-toastify";
import { ShoppingCartIcon } from "@heroicons/react/solid";
import moment from "moment";
import { StateContext } from "../../data/state/state-context";
import * as ActionTypes from "../../data/state/action-types";

const addItemToCartAction = (
  itemId,
  title,
  price,
  description,
  category,
  image
) => {
  return {
    type: ActionTypes.ADD_TO_CART,
    itemId,
    title,
    price,
    description,
    category,
    image,
    qty: 1,
  };
};

function Dish({
  itemId,
  title,
  price,
  description,
  category,
  image,
  hasDealPrice,
  dealPrice,
  dealEndsAt,
  isAvailable,
}) {
  console.log(isAvailable);
  const [rootState, dispatch] = useContext(StateContext);
  const { currency, subscriptionType } = rootState.store;
  const { categories } = rootState.items;
  const addItemToCart = () => {
    const _price = hasDealPrice ? dealPrice : price;
    dispatch(
      addItemToCartAction(itemId, title, _price, description, category, image)
    );
    toast.success(`${title} added to cart`);
  };

  return (
    <Fade bottom>
      <div className="relative flex flex-col bg-themeone-card rounded-md shadow-lg">
        <img
          src={image}
          style={{ height: 160, objectFit: "cover" }}
          alt={title}
          className="rounded-t-md"
          loading="lazy"
        />
        <div className="p-2 flex flex-col h-full">
          <p className="my-3 font-bold capitalize text-base">{title}</p>
          <div
            className="mb-2 font-bold text-gray-700"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {hasDealPrice && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="text-xs text-gray-500 mr-2">Was</p>
                <del style={{ color: "red" }}>
                  <Currency quantity={price} currency={currency} />
                </del>
              </div>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              {hasDealPrice && (
                <p className="text-xs text-gray-500 mr-2">Now</p>
              )}
              <Currency
                quantity={hasDealPrice ? dealPrice : price}
                currency={currency}
              />
            </div>
          </div>
          {hasDealPrice && (
            <div
              className="mb-5 font-bold"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p className="text-xs text-gray-500 mr-2">Offer ends at</p>
              <p className="text-xs text-gray-800">
                {`${moment(dealEndsAt).format("lll")}`}
              </p>
            </div>
          )}
          <p className="text-xs  mb-2 line-clamp-2 text-gray-500">
            {description}
          </p>
          <p className="text-xs  mb-2 line-clamp-2 text-gray-500 capitalize">
            Category: {categories.find(({ id }) => id === category)?.name}
          </p>
          {subscriptionType !== 0 && isAvailable && (
            <button
              className="mt-auto button flex items-center justify-center"
              onClick={addItemToCart}
            >
              <ShoppingCartIcon className="w-4" />
              <span className="ml-2">Add to Cart</span>
            </button>
          )}
        </div>
      </div>
    </Fade>
  );
}

export default Dish;
