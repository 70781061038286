import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ShoppingCartIcon } from "@heroicons/react/solid";
import { StateContext } from "../../data/state/state-context";
import { isStoreAdmin, manageStore, toggleRating } from "../../screens/Store/actions";

function Header({ storeId }) {
  const [rootState, dispatch] = useContext(StateContext);
  const userId = rootState.main.userId || sessionStorage.getItem("userId");
  const { phoneNumber } = rootState.main;
  const { items } = rootState.cart;
  const { placeName, imageSrc, subscriptionType, storeAdmins } = rootState.store;
  const navigate = useNavigate();
  const totalQty = useMemo(() => {
    return items.reduce((prev, curr) => prev + curr.qty, 0);
  }, [items]);

  return (
    <header className="sticky top-0 inset-x-0 z-30 bg-black text-white-color glassmorphism px-6 md:block hidden font-family">
      <div className="flex items-center w-full max-w-screen-xl py-4 xl:space-x-16 lg:space-x-12  space-x-7  mx-auto">
        <div
          className="flex items-center flex-1 text-lg font-bold"
          onClick={() => navigate(`/${storeId}`)}
        >
          <div className="mr-2">
            <img
              src={imageSrc}
              alt="Diner"
              className="cursor-pointer"
              objectFit="cover"
              style={{width: 32, height: 32, borderRadius: '50%'}}
            />
          </div>
          {placeName}
        </div>
        <div className="flex items-center xl:space-x-12  lg:space-x-10 space-x-7  font-medium  lg:text-base text-sm">
          {!userId && (
            <span
              className="link"
              onClick={() => navigate(`/${storeId}/signin`)}
            >
              Login
            </span>
          )}
          <span className="link" onClick={() => navigate(`/${storeId}`)}>
            Home
          </span>
          {userId && subscriptionType !== 0 && (
            <span
              className="link"
              onClick={() => navigate(`/${storeId}/orders`)}
            >
              Orders
            </span>
          )}
          {userId && (
            <span className="link" onClick={() => toggleRating(dispatch, true)}>
              Rate Us
            </span>
          )}
          {isStoreAdmin(storeAdmins, userId) && (
            <span className="link" onClick={() => manageStore(phoneNumber)}>
              Manage Store
            </span>
          )}
        </div>
        {subscriptionType !== 0 && (
          <div
            className="relative cursor-pointer"
            onClick={() => navigate(`/${storeId}/cart`)}
          >
            <ShoppingCartIcon className="xl:w-10 lg:w-9 w-8 link" />
            <div className="absolute -top-2 -right-1 rounded-full text-white bg-themeone-dark p-1 flex items-center justify-center text-xs font-extrabold">
              {totalQty}
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
