import React, { useContext } from "react";
import { MinusSmIcon, PlusIcon } from "@heroicons/react/solid";
import Currency from "react-currency-formatter";
import Fade from "react-reveal/Fade";
import { StateContext } from '../../data/state/state-context';
import * as ActionTypes from '../../data/state/action-types';

const removeFromCartAction = (itemId) => {
  return {
    type: ActionTypes.REMOVE_FROM_CART,
    itemId,
  };
};

const increaseQtyAction = (itemId) => {
  return {
    type: ActionTypes.INCREASE_QTY,
    itemId,
  };
};

const decreaseQtyAction = (itemId) => {
  return {
    type: ActionTypes.DECREASE_QTY,
    itemId,
  };
};

function CartDish({
  itemId,
  title,
  price,
  description,
  category,
  image,
  qty,
  border,
  disabled,
}) {
  const [rootState, dispatch] = useContext(StateContext);
  const { currency } = rootState.store;
  const total = price * qty;

  const removeItemFromCart = () => dispatch(removeFromCartAction(itemId));
  const incQty = () => dispatch(increaseQtyAction(itemId));
  const decQty = () => dispatch(decreaseQtyAction(itemId));

  return (
    <Fade bottom>
      <div
        className={`block bg-white py-6 sm:grid sm:grid-cols-5 ${border ? "border-b border-themeone-light" : ""
          }`}
      >
        <div className="text-center sm:text-left my-auto mx-auto">
          <img
            src={image}
            style={{height: 120, width: 120, objectFit: 'cover'}}
            alt={title}
          />
        </div>

        {/* Middle */}
        <div className="col-span-3 sm:p-4 mt-2 mb-6 sm:my-0">
          <h4 className="mb-3 lg:text-xl md:text-lg text-base capitalize font-medium">
            {title}
          </h4>
          <p className="lg:text-sm text-xs my-2  mb-4 line-clamp-3  text-gray-500">
            {description}
          </p>
          <span className="font-medium md:text-base text-sm">
            {qty} × <Currency quantity={price} currency={currency} /> =
            <span className="font-bold text-gray-700 mx-1">
              <Currency quantity={total} currency={currency} />
            </span>
          </span>
        </div>

        {/* Buttons on the right of the dishes */}
        <div className="flex flex-col space-y-4 my-auto  justify-self-end">
          <div className="flex justify-between">
            <button
              className={`button sm:p-1 ${disabled ? "opacity-50" : ""}`}
              onClick={decQty}
              disabled={disabled}
            >
              <MinusSmIcon className="h-5" />
            </button>
            <div className="p-2 whitespace-normal sm:p-1 sm:whitespace-nowrap">
              <span className="font-bold md:text-base text-sm text-gray-700">
                {qty}
              </span>
            </div>
            <button
              className={`button sm:p-1 ${disabled ? "opacity-50" : ""}`}
              onClick={incQty}
              disabled={disabled}
            >
              <PlusIcon className="h-5" />
            </button>
          </div>
          <button
            className={`button py-2  lg:px-10 md:px-8 px-6 ${disabled ? "opacity-50" : ""
              }`}
            onClick={removeItemFromCart}
            disabled={disabled}
          >
            Remove
          </button>
        </div>
      </div>
    </Fade>
  );
}

export default CartDish;
